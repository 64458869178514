<div class="container-fluid">

    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">View Tracker</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>

            </div>


        </div>

    </div>
    <div class="contentbox mt-3">
        <div class="row customepadding  ">
            <div class="col-xl-3 ms-auto">
                <input class='search-box form-control' #data placeholder="Search" type="text" (keyup)="filterData(data.value)" />
              </div>
            <div class="col-md-12">
               
                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" matSort matSortActive="date" matSortDirection="desc"
                        (matSortChange)="sortData($event)" class="row-border hover tablelist">

                        <ng-container matColumnDef="LR_no">
                            <th mat-header-cell *matHeaderCellDef>System LR No.</th>
                            <td mat-cell *matCellDef="let receipt; let i = index"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.LR_no}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="manualLR_no">
                            <th mat-header-cell *matHeaderCellDef>Manual LR No.</th>
                            <td mat-cell *matCellDef="let receipt; let i = index"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]">
                                {{receipt.manualLR_no}} </td>
                        </ng-container>


                        <ng-container matColumnDef="vehicle_Types">
                            <th mat-header-cell *matHeaderCellDef class="text-center"> Vehicle Types </th>
                            <td mat-cell *matCellDef="let receipt">{{receipt.vehicleType}}

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="vehicle_id">
                            <th mat-header-cell *matHeaderCellDef>Vehicle No.</th>
                            <td mat-cell *matCellDef="let receipt"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.vehicle_id}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="from">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
                            <td mat-cell *matCellDef="let receipt"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.from}} </td>
                        </ng-container>

                        <ng-container matColumnDef="to">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>To</th>
                            <td mat-cell *matCellDef="let receipt"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.to}} </td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                            <td mat-cell *matCellDef="let receipt"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.date |
                                date:'yyyy-MM-dd'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="stateCode">
                            <th mat-header-cell *matHeaderCellDef class="text-center"> State Code </th>
                            <td mat-cell *matCellDef="let receipt">{{receipt.stateCode}}

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="fixAdhoc">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fix/Adhoc</th>
                            <td mat-cell *matCellDef="let receipt"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.fixAdhoc}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="startmeterReading">
                            <th mat-header-cell *matHeaderCellDef>Start Meter Reading</th>
                            <td mat-cell *matCellDef="let receipt"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]">
                                {{receipt.startmeterReading}} </td>
                        </ng-container>

                        <ng-container matColumnDef="endmeterReading">
                            <th mat-header-cell *matHeaderCellDef>End Meter Reading</th>
                            <td mat-cell *matCellDef="let receipt"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]">
                                {{receipt.endmeterReading}} </td>
                        </ng-container>

                        <ng-container matColumnDef="tripStartDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Trip Start Date</th>
                            <td mat-cell *matCellDef="let receipt"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]">
                                {{receipt.tripStartDate | date:'yyyy-MM-dd'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="tripEndDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Trip End Date</th>
                            <td mat-cell *matCellDef="let receipt"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]">
                                {{receipt.tripEndDate | date:'yyyy-MM-dd'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="noofTools">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>No. of Tolls</th>
                            <td mat-cell *matCellDef="let receipt"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]">
                                {{receipt.noofTools}} </td>
                        </ng-container>

                        <ng-container matColumnDef="up_down_km">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usage KM</th>
                            <td mat-cell *matCellDef="let receipt"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]">
                                {{receipt.up_down_km}} </td>
                        </ng-container>

                        <ng-container matColumnDef="extrahour">
                            <th mat-header-cell *matHeaderCellDef>Extra Hours</th>
                            <td mat-cell *matCellDef="let receipt"
                                [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]">
                                {{receipt.extrahour}} </td>
                        </ng-container>



                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>

                </div>
                <mat-paginator #paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>