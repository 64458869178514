import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SuperAdminService } from 'src/app/super-admin.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-addfuel-expense',
  templateUrl: './addfuel-expense.component.html',
  styleUrls: ['./addfuel-expense.component.css']
})
export class AddfuelExpenseComponent implements OnInit {

  submitBtn: boolean = false;
  apiUrl = environment.domain;
  fuelSlipPreview:any;
  fuelSlips: Array<File> = [];
  docURL: any;
  
  paramId: any;
  exId: any;
  id: any;
  particularExpense: any;
  fuelPumpssss: any = [{}];
  filteredfuelPump: Observable<string[]>;


  fuel_expense = this.formBuilder.group({
    fuelPump: ['', Validators.required],
    dateOfExpenses: ['', Validators.required],
    fuelExpenseAmount: ['', Validators.required],
    paymentMode: ['', Validators.required],
    fuelQuantity: ['', Validators.required],
    unit: ['', Validators.required],
    fuelType: ['', Validators.required],
    driverName: [''],
    fuelAttachment: [''],
    lr_id: this.data,


  })
  url: any;
  fuelPumps: any;
  rawdata: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public formBuilder: FormBuilder, public superAdminServices: SuperAdminService, public toaster: ToastrService, private router: Router, private activatedRoute: ActivatedRoute, public dialogRef: MatDialogRef<AddfuelExpenseComponent>, private sanitizer:DomSanitizer) {
    this.activatedRoute.params.subscribe((param) => {
      this.paramId = param.id
      this.id = param.id
      // console.log("add fuel expense", this.paramId)
      // console.log("edit fuel expense", this.data)
      if(this.data.edit){
      this.getParticularFuelExpense(this.data.edit);
      }
    })

  }

  get ff() {
    return this.fuel_expense.controls
  }

  ngOnInit(): void {
    // console.log(this.data)
    this.getFuel()

  }

  fuelExpenseSubmit() {

    // console.log("fuel expense Values", this.fuel_expense.value)

    this.submitBtn = true;
    if (this.data.edit) {
      if (this.fuel_expense.valid) {
        let form = {
          "fuelPump": this.fuel_expense.get('fuelPump').value,
          "dateOfExpenses": this.fuel_expense.get('dateOfExpenses').value,
          "fuelExpenseAmount": this.fuel_expense.get('fuelExpenseAmount').value,
          "paymentMode": this.fuel_expense.get('paymentMode').value,
          "fuelQuantity": this.fuel_expense.get('fuelQuantity').value,
          "unit": this.fuel_expense.get('unit').value,
          "fuelType": this.fuel_expense.get('fuelType').value,
          "driverName": this.fuel_expense.get('driverName').value,
          // fuelAttachment: ,
          "lr_id": this.paramId,
          "_id": this.data.edit

        }
        // console.log(form)
        const formData:any = new FormData();
        const files: Array<File> = this.fuelSlips
        // console.log("uploaded File", this.fuelSlips)

        formData.append("uploads[]", this.fuelSlips[0])
        formData.append("myFormData", JSON.stringify(form) )

        // for (const value of formData.values()) {
        //   console.log("formdata",value);
        // }

        this.superAdminServices.editFuelExpense( formData ).subscribe((res: {rawdata: any, status:any,message:any,authorization:any,code : any}) => {
          if (res.status == true) {
            this.toaster.success(res.message)
            this.dialogRef.close(res.status);

          } else {
            if (res.status == 401) {
              this.toaster.error(res.message)
            } else {
              this.toaster.error(res.message)
            }
          }
        })
      }

      // this.superAdminServices.addFuelExpense({formData}).subscribe((res: any) => {

      // })
    } else {
      if (this.data.add) {
        if (this.fuel_expense.valid) {
          this.submitBtn = true;
          let form = {

            "fuelPump": this.fuel_expense.get("fuelPump").value,
            "dateOfExpenses": this.fuel_expense.get("dateOfExpenses").value,
            "fuelExpenseAmount": this.fuel_expense.get('fuelExpenseAmount').value,
            "paymentMode": this.fuel_expense.get('paymentMode').value,
            "fuelQuantity": this.fuel_expense.get('fuelQuantity').value,
            "unit": this.fuel_expense.get("unit").value,
            "fuelType": this.fuel_expense.get("fuelType").value,
            "driverName": this.fuel_expense.get("driverName").value,
            // "fuelAttachment": this.fuelSlips,
            "lr_id": this.data.add,
        

          }
          const formData: any = new FormData();
          const files: Array<File> = this.fuelSlips
          // console.log("upload data", this.fuelSlips)
          // for (let i = 0; i < this.fuelSlips.length; i++) {
            

          // }
          formData.append("uploads[]", this.fuelSlips[0])
          formData.append("myFormData", JSON.stringify(form))
          // console.log("doinnn", formData.values())

          // for (const value of formData.values()) {
          //   console.log("formdata",value);
          // }

          this.superAdminServices.addFuelExpense(formData).subscribe((res: {rawdata: any, status:any,message:any,authorization:any,code : any}) => {
            if (res.status == true) {
              this.toaster.success(res.message);
              this.dialogRef.close(res.status);

            } else {
              if (res.status == 401) {
                this.toaster.error(res.message)
              } else {
                this.toaster.error(res.message)
              }
            }

          })



        }
      }
    }
  }

  getFuel() {
    this.superAdminServices.getFuel().subscribe((res: any) => {
      if (res.status == true) {
         this.rawdata = res.data
        console.log("rawdatarawdata",this.rawdata)

        this.rawdata = this.rawdata.map((x)=> x.fuel_pump)
        console.log("rawdatarawdata2",this.rawdata)
        


        this.filteredfuelPump = this.ff.fuelPump.valueChanges.pipe(
          startWith(''),
          map(value => this._filterfuelPump(value || '')),
        );
      } else {
        if (res.status == 401) {
          this.toaster.error(res.message)
          window.sessionStorage.clear()
        } else {
          this.toaster.success(res.message)
        }
      }
    })
  }

  getParticularFuelExpense(id: any) {
    this.superAdminServices.getParticularFuelExpense(id).subscribe((res: any) => {
      if (res.status == true) {
        this.particularExpense = res.data[0]
        // console.log("particular expense", this.particularExpense.fuel_slip[0])
        this.initForm();
      }else {
        if(res.status == 401){
          this.toaster.error(res.message)
        }else {
          this.toaster.error(res.message)
        }
      }
    })
  }

  initForm() {
    this.fuel_expense.patchValue({
      fuelPump: this.particularExpense.fuel_pump,
      dateOfExpenses: this.particularExpense.date_of_expenses,
      fuelExpenseAmount: this.particularExpense.fuel_expense_amount,
      paymentMode: this.particularExpense.payment_mode,
      fuelQuantity: this.particularExpense.fuel_quantity,
      unit: this.particularExpense.unit,
      fuelType: this.particularExpense.fuel_type,
      driverName: this.particularExpense.driver_name,
     
    })
    if(this.particularExpense.fuel_slip){
      // console.log("fuel Slip", this.particularExpense.fuel_slip[0])
      this.fuelSlipPreview = this.apiUrl+'/'+this.particularExpense.fuel_slip[0];
    }

  }

  fileChangeEventPODAttchment(fileInput: any) {
    // console.log("attachPOD", fileInput.target.files)
    this.fuelSlips = [];
    var reader = new FileReader();
    for (var i = 0; i < fileInput.target.files.length; i++) {
      this.fuelSlips.push(fileInput.target.files[i]);
      // console.log("change event on upload file", this.fuelSlips[i])
    }
    reader.readAsDataURL(fileInput.target.files[0]);
    reader.onload = (event: any) => { // called once readAsDataURL is completed
      // console.log(event);
      const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string)
      this.url = event.target.result;
      this.fuelSlipPreview = reader.result;
    }
  }


resetimg(image:any){
    switch (image) {
      case'fuelSlip':
        this.fuelSlipPreview =''
        break;
      default:

    }
  }



  private _filterfuelPump(value: string): string[] {
    const filterValue = value.toLowerCase();
    console.log("rawdatarawdata",this.filteredfuelPump)
    return this.rawdata.filter(option => option.toLowerCase().includes(filterValue));
    
  }
  




  // getfuelPump(event: any) {
    
  //    this.fuelPumpssss = [{}];
  //    this.fuelPumpssss.push(event.option.value);
     
  //    console.log("kkkkkkkkkk",this.rawdata, this.fuelPumpssss)
    
  //   let temp = []
  //   this.rawdata.map((x) => {x.fuel_pump
      
  //   })

  //   this.fuel_expense.patchValue({
  //     fuelPump: temp[0].fuel_pump
  //   })
  //  console.log("fuelPumpfuelPump",)
  // }


}
