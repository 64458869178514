<div class="position-relative pb-5">
    <button mat-dialog-close class="btn btn-dark position-absolute shadow-none top-0 fs-4 end-0"><i
        class="fa-solid fa-xmark"></i></button>
</div>
    <div class="row">
        <div class="col-xl-6" *ngIf="front">
            <h4>  Front</h4>
            <div class="imageBox position-relative">
                <img [src]="sanitizeImageUrl(imageURL)">
            </div>
        </div>
        <div class="col-xl-6" *ngIf="back"> 
            <h4>Back</h4>
            <div class="imageBox position-relative">
               
                <!-- <button mat-dialog-close class="btn btn-dark position-absolute shadow-none top-0 fs-4 end-0"><i
                        class="fa-solid fa-xmark"></i></button> -->
                <img [src]="sanitizeImageUrl(imageURLback)">
            </div>
        </div>
    </div>
