import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-view-other-expenses',
  templateUrl: './view-other-expenses.component.html',
  styleUrls: ['./view-other-expenses.component.css']
})
export class ViewOtherExpensesComponent {
  paramID: any;
  particular_other_expenses: any;

  constructor(public activatedRoute: ActivatedRoute, public superAdminServices: SuperAdminService, public toaster: ToastrService, public dialog: MatDialog,){
    this.activatedRoute.params.subscribe(param => {
      this.paramID = param.id
      this.getParticularotherExpense(this.paramID)
      console.log("param",this.paramID);
      
    })

  }

  
  ngOnInit(): void {

   
  }


  getParticularotherExpense(id:any){
    this.superAdminServices.getParticularotherExpense(id).subscribe((res:any)=> {
      if(res.status == true){
        this.particular_other_expenses = res .data[0]
        console.log("particular_other_expenses",this.particular_other_expenses);
      }
    })

  }

  backTo() {
    window.history.back()
  }

}
