<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">Miscellaneous expenses </h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left"
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">Miscellaneous expenses</li>
                    <li class="breadcrumb-item" (click)="backTo()">Office Expenses</li>
                    <li class="breadcrumb-item active" aria-current="page">Add Expense </li>

                </ol>
            </nav>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
            <form [formGroup]="addofficeExpenseForm" (ngSubmit)="addofficeExpense()" enctype="multipart/form-data">
                <div class="contentbox">
                    <div class="row account mb-3">
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Supervisor Name<sup text="danger">*</sup></label>
                                <select id="supervisornamectrl" formControlName="supervisorname"
                                    placeholder="Select Status" class="form-control shadow-none">
                                    <option selected disabled value="null">--Select Supervisor Name--</option>
                                    <option *ngFor="let s of superVisor" value="{{s._id}}">{{s.full_name}}</option>

                                </select>
                                <span class="text-danger"
                                    *ngIf="ff.supervisorname.invalid && (ff.supervisorname.touched || submitLRClicked)">
                                    Please Select SuperVisor Name
                                </span>

                            </div>
                        </div>

                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">

                                <label for="">Expense Category<sup text="danger">*</sup></label>
                                <select id="expenseCategoryctrl" formControlName="expenseCategory"
                                    class="form-control shadow-none">
                                    <option disabled value="null">--Select Expense Category--</option>
                                    <option *ngFor="let cat of subCat" value="{{cat._id}}">{{cat.expenseSubCategory}}
                                    </option>
                                </select>
                                <span class="text-danger"
                                    *ngIf="ff.expenseCategory.invalid && (ff.expenseCategory.touched || submitLRClicked)">
                                    Please Select Expense Category
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Company Name<sup text="danger">*</sup></label>
                                <select id="companyNameCtrl" formControlName="companyname"
                                    class="form-control shadow-none">
                                    <option disabled value="null">--Select Company Name--</option>
                                    <option *ngFor="let c of company" value="{{c._id}}">{{c.clientName}}</option>

                                </select>
                                <span class="text-danger"
                                    *ngIf="ff.companyname.invalid && (ff.companyname.touched || submitLRClicked)">
                                    Please Select Company Name
                                </span>

                            </div>
                        </div>

                        <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Company Name</label>
                            <select id="companyNameCtrl" formControlName="companyname" class="form-control shadow-none">
                                <option disabled value="">--Select Company Name--</option>
                                <option *ngFor="let c of company" value="{{c.company_name}}">{{c.company_name}}</option>

                            </select>
                        </div>
                    </div> -->

                        <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Vehicle Type</label>
                            <select id="vehicleTypectrl" formControlName="vehicleType" placeholder="Select Vehicle Type"
                                class="form-control shadow-none">
                                <option disabled value="">--Select Vehicle Type--</option>
                                <option *ngFor="let c of vehicletypes" value="{{c.vehicletype_name}}">
                                    {{c.vehicletype_name}}</option>

                            </select>

                        </div>
                    </div> -->
                        <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Tea Expense</label>
                            <input type="text" id="teaExpensectrl" class="form-control shadow-none"
                                placeholder="Enter Tea Expense" formControlName="teaExpense">
                        </div>
                    </div> -->
                        <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Water Expense</label>
                            <input type="text" id="waterExpensectrl" class="form-control shadow-none"
                                placeholder="Enter Water Expense" formControlName="waterExpense">
                        </div>
                    </div> -->
                        <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Conveyance Expense</label>
                            <input type="text" id="conveyanceExpensectrl" class="form-control shadow-none"
                                placeholder="Enter Conveyance Expense" formControlName="conveyanceExpense">
                        </div>
                    </div> -->

                        <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Electricity Expense</label>
                            <input type="text" id="electricityExpensectrl" class="form-control shadow-none"
                                placeholder="Enter Electricity Expense " formControlName="electricityExpense">
                        </div>
                    </div> -->

                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">

                                <label for="">Expense Amount<sup text="danger">*</sup></label>
                                <input type="number" id="expenseAmountctrl" class="form-control shadow-none"
                                    placeholder="Enter Expense Amount " formControlName="expenseAmount">
                                <span class="text-danger"
                                    *ngIf="ff.expenseAmount.invalid && (ff.expenseAmount.touched || submitLRClicked)">
                                    Please Enter Expense Amount
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">

                                <label for="">Expense Date<sup text="danger">*</sup></label>
                                <input type="date" id="expenseDatectrl" class="form-control shadow-none"
                                    formControlName="expenseDate">
                                <span class="text-danger"
                                    *ngIf="ff.expenseDate.invalid && (ff.expenseDate.touched || submitLRClicked)">
                                    Please Enter Expense Amount
                                </span>
                            </div>
                        </div>

                        <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Head</label>
                            <input type="text" id="expenseHeadctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Head " formControlName="expenseHead">
                        </div>
                    </div> -->
                        <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Other Charges</label>
                            <input type="text" id="otherChargesctrl" class="form-control shadow-none"
                                placeholder="Enter Other Charges" formControlName="otherCharges">
                        </div>
                    </div> -->

                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">

                                <label for="">Expense Description<sup text="danger">*</sup></label>
                                <input type="text" id="expenseDescriptionctrl" class="form-control shadow-none"
                                    placeholder="Enter Expense Description " formControlName="expenseDescription">
                                    <span class="text-danger"
                                    *ngIf="ff.expenseDescription.invalid && (ff.expenseDescription.touched || submitLRClicked)">
                                    Please Enter Expense Description
                                </span>
                            </div>
                        </div>


                        <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Room Rent</label>
                            <input type="text" id="roomRentctrl" class="form-control shadow-none"
                                placeholder="Enter Room Rent" formControlName="roomRent">
                        </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Stationary Expense</label>
                            <input type="text" id="stationaryExpensectrl" class="form-control shadow-none"
                                placeholder="Enter Stationary Expense " formControlName="stationaryExpense">
                        </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Salary</label>
                            <input type="text" id="salaryctrl" class="form-control shadow-none"
                                placeholder="Enter Salary" formControlName="salary">
                        </div>
                    </div> -->
                        <!-- <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Mobile Expense</label>
                            <input type="text" id="mobileExpensectrl" class="form-control shadow-none"
                                placeholder="Enter Mobile Expense " formControlName="mobileExpense">
                        </div>
                    </div> -->

                        <div class="col-xl-12 mb-3">
                            <div class="detailsBox">
                                <h4>Upload Document</h4>
                                <div class="row">
                                    <div class="col-lg-2 col-xl-2 col-sm-6 col-12 col-md-4">
                                        <div class="form-group accountsinp  mb-3">
                                            <label for="">Expense Attachments<sup text="danger">*</sup></label>
                                            <input type="file" accept="image/*,.pdf,.doc"
                                                (change)="fileChangeEventattachReceipt1($event)" id="LRReceipt1ctrl"
                                                class="form-control shadow-none d-none" formControlName="LRReceipt1">

                                            <label class="uploadimgNewDesign" for="LRReceipt1ctrl" *ngIf="!Attachments">
                                                <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                                <h5>Upload Expense Attachments</h5>
                                            </label>
                                            <div class="viewImage" *ngIf="Attachments">
                                                <div class="imgBoxp">
                                                    <button class="resetImg" (click)="resetimg('attechment')"><i
                                                            class="fa-solid fa-xmark"></i></button>
                                                    <img [id]="Attachments"
                                                        onClick="viewImages(this, 'Expense Attachments')"
                                                        [src]="Attachments" alt="Expense Attachments" />

                                                </div>

                                            </div>
                                            <span class="text-danger"
                                                *ngIf="ff.LRReceipt1.invalid && (ff.LRReceipt1.touched || submitLRClicked)">
                                                Please Upload Attachments
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a #showSlip [href]="slipURL" target="_blank"></a>
                        <div class="col-md-12 text-end">
                            <button class="btn btn-outline-dark" type="button" (click)="resetForm()"
                                mat-raised-button>Reset</button>
                            <button class="btn btn-dark ms-2" type="submit" mat-raised-button>Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>