<div class="mainebox">
  <div class="leftpart">
   
  </div>

  <div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
      <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
        <div class="d-flex align-items-center justify-content-between">
          <h1 class="fs-4 fw-bold">{{pageName}}</h1>
          <button
            class="btn btn-dark btn-sm"
            matTooltip="Back"
            matTooltipPosition="left"
            mat-raised-button
             (click)="backTo()"
           
          >
            <i class="fa-solid fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="contentbox  shadow-none mt-3">
      <div class="row justify-content-end">
        <div class="col-md-3">
          <div class="table-responsive">
            <input
              class="form-control search-box"
              #data 
              placeholder="search"
              type="text"
              (keyup)="filterData(data.value)"
            />
      </div>
      </div>
      <div class="row customepadding">
        <div class="col-md-12">
          <div class="table-responsive">
            <!-- <input
              class="form-control search-box"
              #data 
              placeholder="search"
              type="text"
              (keyup)="filterData(data.value)"
            /> -->
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              matSortActive="date"
              matSortDirection="desc"
              (matSortChange)="sortData($event)"
              class="row-border over tablelist"
            >
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef>S.no</th>
              <td mat-cell *matCellDef="let client; let i = index">
                {{ paginator.pageIndex * paginator.pageSize + (i + 1) }}
              </td>
            </ng-container>

              <ng-container matColumnDef="clientName">
                <th mat-header-cell *matHeaderCellDef>Company Name</th>
                <td
                  mat-cell
                  *matCellDef="let client"
                >
                {{ client.clientName }}
                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef>Company Email</th>
                <td
                  mat-cell
                  *matCellDef="let client"
                >
                {{ client.state }}
                </td>
              </ng-container> -->

              <ng-container matColumnDef="created_date">
                <th mat-header-cell *matHeaderCellDef >
                    Date
                </th>
                <td mat-cell *matCellDef="let client">
                    {{ client.created_date | date : "yyyy-MM-dd" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-center">
                  Action
                </th>
                <td mat-cell *matCellDef="let client">
                  <div class="d-flex justify-content-center align-items-center">
                    <button
                      [routerLink]="[
                        '/superAdmin/lrmanagement/invoice',
                        client._id
                      ]"
                      class="btn btn-block"
                    >
                      <i matTooltip="View" class="fa-solid fa-eye"></i>
                    </button>
                    <button
                      (click)="getParticularInvoice(client._id)"
                      class="btn btn-block"
                    >
                      <i matTooltip="print" class="fas fa-print"></i>
                    </button>
                    <button
                      #printBtn
                      [useExistingCss]="true"
                      printSectionId="print-section"
                      [style.display]="'none'"
                      
                    ></button>
                    <button
                      class="btn btn-block"
                      (click)="sendInvoice('print-section', client._id)"
                    >
                      <i matTooltip="send" class="fa fa-paper-plane"></i>
                    </button>
                    <button
                      (click)="deleteInvoice(client._id)"
                      class="btn btn-block"
                    >
                      <i matTooltip="Delete" class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </ng-container>

             

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <mat-paginator
            #paginator
            [length]="100"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
           
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
</div>
