<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View Other Expenses</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" (click)="backTo()">Manage LR </li>
                    <li class="breadcrumb-item active" aria-current="page">View Other Expenses</li>

                </ol>
            </nav>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
            <div class="contentbox">
                <div class="row account">
                    <div class="col-xl-12">
                        <div class="detailsBox">
                            <div class="row">
                                <div class="col-xl-4">
                                    <label for="">Date</label>
                                    <h5>{{this.particular_other_expenses?.dateOfExpenses}}</h5>
                                </div>
                                <div class="col-xl-4">
                                    <label for="">Expense Amount</label>
                                    <h5>{{this.particular_other_expenses?.ExpenseAmount}}</h5>
                                </div>
                                <div class="col-xl-4">
                                    <label for="">Payment Mode</label>
                                    <h5>{{this.particular_other_expenses?.paymentMode}}</h5>
                                </div>

                                <div class="col-xl-4">
                                    <label for="">Expense Type</label>
                                    <h5>{{this.particular_other_expenses?.Expenses_type}}</h5>
                                </div>


                                <div class="col-xl-4">
                                    <label for="">Description</label>
                                    <h5>{{this.particular_other_expenses?.description}}</h5>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>