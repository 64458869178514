<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View LR</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lsmanagement">Manage LR</li>
                    <li class="breadcrumb-item active" aria-current="page">View LR</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12">

            <div class="contentbox">
                <div class="text-center ">
                    <!--  [useExistingCss]="true"    styleSheetFile="../../../../assets/css/printcssn.css" -->
                    <button mat-raised-button [useExistingCss]="true" class="btn btn-outline-dark btn-sm px-4"
                        printSectionId="print-section2" ngxPrint>Print</button>&nbsp;
                    <button mat-raised-button class="btn btn-dark btn-sm px-4"
                        (click)="sendEmail('print-section')">Email</button>
                </div>

                <div class="ahujaInvoice" id="print-section2">
                    <table>
                        <tr>
                            <td style="width:80%;">
                                <table>
                                    <tr>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td style="padding-right: 5px;">
                                                        <img src="../../../../assets/images/ahujalogo.png"
                                                            class="img-fluid" width="150" height="150">
                                                    </td>
                                                    <td style="padding-left: 5px;">
                                                        <h1 style="font-size: 47px; font-weight: bold; color: #f8999f;">
                                                            Ahuja Transport Company</h1>
                                                        <h2
                                                            style="font-size:1.25rem; text-align: right; margin-bottom: 0;">
                                                            FLEET OWNER & TRANSPORT CONTRACTORS</h2>
                                                    </td>
                                                </tr>
                                            </table>

                                            <h3 style="font-size:13px; text-align: center; margin-bottom: 0;">DELHI,
                                                MAHARASHTRA, WEST BANGAL, HARYANA, PUNJAB, RAJASTHAN, BIHAR, HIMACHAL,
                                                U.P., M.P., GUJARAT & SOUTH</h3>
                                        </td>
                                    </tr>

                                </table>
                                <table class="addressBOx" style="width: 100%;">
                                    <tr>
                                        <td style="width:36%">
                                            <h4 style="font-size: 13px; text-align:left; margin-bottom: 0;">PAN:
                                                AAEPA0775C</h4>
                                            <h4 style="font-size: 13px; text-align:left;">GSTIN: 06AAEPA0775C1ZD</h4>
                                            <div class="serviceBox">
                                                <p style="font-size: 11px;">Service Tax@2.55(After abetment) on Gross
                                                    amount payble is to be paid extra by the consignor/consinee to the
                                                    govt. Deptt. directly for the consignment covered under this
                                                    document</p>
                                            </div>
                                        </td>
                                        <td style="width:64%" class="text-center">
                                            <h5 style="font-size: 16px;">Head Office: Plot No. 68, Sector-58,</h5>
                                            <h5 style="font-size: 16px;">Transport Nagar, Ballabhgarh, Faridabad</h5>
                                            <h5 style="font-size: 16px;">Contact Office: H.No. 21, Sector-15, Faridabad
                                                - 121007 Haryana</h5>
                                            <h5 style="font-size: 16px;">E-mail : ahuja.transport.co@gmail.com</h5>



                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <h4 style="font-size: 16px; font-weight:600; text-align:right;">(M) 9582260343,
                                    9711897196, 9810412342, 9810065143</h4>
                                <div class="undercarrigeBox">
                                    <h1>Under Carriage by Road Act, 2007</h1>
                                    <p>Registration N-o. : 498/STA/08/2016</p>
                                    <p>Issue Date : 19-08-2016</p>
                                    <p>Valid Upto : 18-08-2016</p>
                                </div>
                            </td>

                        </tr>
                        <tr>
                            <td style="padding-top:5px" colspan="2">
                                <table class="noticeBoxMaine">
                                    <tr>
                                        <td>
                                            <div class="noticeBox">
                                                <h1 style="color:#f8999f; font-weight:700">NOTICE</h1>
                                                <p style="line-height: 18px;">The Consignment covered by this set to Special Lorry Receipt Forms shall be stored at the destination under the control of the Transport Operator and shall be delivered to or to the order of the Consignee Bank or whose name is mentioned in the Lorry Receipt it will under no circumstances be delivered to any one without the written authority from the Consignee Bank or its order endorsed on the Consignee copy or on a separate Letter</p>
                                                <hr>
                                                <p style="line-height: 18px;">No Credit on Inputs or Capital goods used  for provision of Service has been taken not the benefit of Ntfn No. 12/2003-ST. Service Tax as applicable by the Consignee /Consignor. </p>
                                            </div>
                                        </td>
                                        <td style="padding: 0 10px;">
                                            <div class="noticeBox drivercopy" style="border-color: #000;">
                                                <h1>DRIVER COPY</h1>
                                                <h2>At OWNER'S RISK</h2>
                                                <h3>INSURANCE</h3>
                                                <p>The customer has stated that:</p>
                                                <p><input type="checkbox"> He has not insured the consignment</p>
                                                <p><input type="checkbox"> He has Insured the consignment</p>
                                                <div class="insuImp">
                                                    <p style="margin-top: 3px;">Company <span
                                                            class="writeBox">{{this.companyName}}</span> </p>
                                                </div>
                                                <div class="insuImp">
                                                    <p style="flex: 0 0 190px;">Policy No. <span
                                                            class="writeBox"></span> </p>
                                                    <p>Date <span class="writeBox"></span> </p>
                                                </div>
                                                <div class="insuImp">
                                                    <p style="flex: 0 0 190px;">Amount <span class="writeBox"></span>
                                                    </p>
                                                    <p>Risk <span class="writeBox"></span> </p>
                                                </div>


                                            </div>
                                        </td>
                                        <td>
                                            <div class="noticeBox fromtoBOx" style="border-color: #000;">
                                                <h2>From :{{this.particlarLR?.from}}</h2>
                                                <h2>To :{{this.particlarLR?.to}}</h2>
                                                <h2>Truck No.:{{this.particlarLR?.vehicle_id}}</h2>
                                                <div class="insuImp">
                                                    <!-- <h2 style="padding:5px 0px 5px 15px; border: 0;">No.: ATC/23-24/608
                                                    </h2> -->
                                                    <h2 style="padding:5px 0px 5px 15px; font-weight: 600; border: 0;">Manual LR No. : {{this.particlarLR?.manualLR_no}}
                                                    </h2>
                                                    <h2 style="padding:5px 0px 5px 15px; font-weight: 600; border: 0;">Date : {{ particlarLR?.date | date:'dd-MM-yyyy' }}</h2>
                                                    <!-- <p style="color:#000">Date <span class="writeBox">06/03/2024</span>
                                                    </p> -->
                                                </div>
                                            </div>
                                        </td>
                                        

                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="nameaddressform">
                                    <tr>
                                        <td>Consignor's Name</td>
                                        <td>
                                            <table style="width: 100%;">
                                                <tr>
                                                    <td style="width: 55%;">
                                                        <p>{{this.particlarLR?.consignor_name}} </p>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex gap-3">
                                                            <h4>City</h4>

                                                            <p> {{ this.consignor_city}}</p>
                                                        </div>
                                                    </td>
                                                    <td style="padding-right: 0;">
                                                        <div class="d-flex gap-3">

                                                            <h4>GSTIN</h4>

                                                            <p>{{particlarLR?.consignor_GSTIN}} </p>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Consignee Name</td>
                                        <td>
                                            <table style="width: 100%;">
                                                <tr>
                                                    <td style="width: 55%;">
                                                        <p>{{this.particlarLR?.consignee_name}} </p>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex gap-3">
                                                            <h4>City</h4>

                                                            <p> {{this.consignee_city}}</p>
                                                        </div>
                                                    </td>
                                                    <td style="padding-right: 0;">
                                                        <div class="d-flex gap-3">

                                                            <h4>GSTIN</h4>

                                                            <p>{{particlarLR?.GSTIN}} </p>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </table>
                                        </td>
                                    </tr>

                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="invoiceDetails">
                                    <thead>
                                        <tr>
                                            <td rowspan="2">No. of Pkgs.</td>
                                            <td rowspan="2">Description (Said to Contain)</td>
                                            <td colspan="2">Weight</td>
                                            <td rowspan="2">Rate</td>
                                            <td colspan="2">Freight to Pay</td>
                                            <td colspan="2">Freight to Pay</td>
                                        </tr>
                                        <tr>
                                            <td>Actual</td>
                                            <td>Charged</td>
                                            <td>Rs.</td>
                                            <td>P.</td>
                                            <td>Rs.</td>
                                            <td>P.</td>

                                        </tr>
                                    </thead>
                                   
                                    <tbody>
                                        <tr>
                                            <td style="border-bottom:0;">&nbsp;{{this.particlarLR?.no_of_package}}</td>
                                            <td style="border-bottom:0;">&nbsp; {{this.particlarLR?.description}}</td>
                                            <td style="border-bottom:0;">&nbsp; {{this.particlarLR?.quantity}} , <br> {{this.particlarLR?.vehicleType}}</td>
                                            <td style="border-bottom:0;">&nbsp; {{this.particlarLR?.quantity}} , <br> {{this.particlarLR?.vehicleType}}</td>
                                            <td style="border-bottom:0;">&nbsp;</td>
                                            <td class="fw-semibold">Fraight</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td class="fw-semibold">Surcharge</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td class="fw-semibold">Hamil</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td class="fw-semibold">St. Charge</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td class="fw-semibold">A.O.C.</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-semibold" colspan="4">
                                                <table class="invoiceno" border="0">
                                                    <tr>
                                                        <td style="border-right: 1px solid #000; padding: 0;"
                                                            class="fw-semibold">Invoice No.
                                                            {{this.particlarLR?.InvoiceNo}}</td>
                                                        <td class="fw-semibold">E-way bill
                                                            No.{{this.particlarLR?.road_permit_no}}
                                                        <td>

                                                    </tr>
                                                </table>
                                            </td>
                                            <td style="border-top: 0; border-bottom: 0;">&nbsp;</td>
                                            <td class="fw-semibold">S.T.Ch.</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-semibold" colspan="4">Rs. - {{this.particlarLR?.Frieght}}</td>

                                            <td style="border-top: 0;">&nbsp;</td>
                                            <td class="fw-semibold">G. Total</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                       
                        <tr>
                            <td colspan="2">
                                <table class="signatureBox">
                                    <tr>
                                        <td>Subject to Faridabad Jurisdiction Only</td>
                                        <td class="text-end">Driver Signature</td>
                                        <td class="text-end">For Ahuja Transport Company</td>

                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="invoicetabl jscargoInvoice mt-5" hidden>
                    <table>
                        <tr>
                            <td style="vertical-align: top;">
                                <p style="font-size: 10px; font-weight: bold; margin: 0; white-space: pre;">GST No. :
                                    352458697458</p>
                                <p style="font-size: 10px; font-weight: bold; margin: 0; white-space: pre;">PAN No. :
                                    AAPFJ3364O</p>
                            </td>
                            <td align="center">
                                <p style="font-size: 10px; font-weight: bold; margin: 0; white-space: pre;">JAI GURU JI
                                </p>
                                <div class="header">JAI SHANKAR CARGO MOVERS</div>
                                <div class="header2">FLEET OWNERS & TRANSPORT CONTRACTORS</div>
                                <div>Special Service for Bengal (All Over India)</div>
                                <div class="address">Delhi Office: AS-23, Sanjay Gandhi Transport Nagar, Delhi - 110042
                                    - Email : shreeshi_khuller@yahoo.com</div>
                            </td>
                            <td style="vertical-align: top;" align="right">
                                <p style="font-size: 10px; font-weight: bold; margin: 0; white-space: pre;"
                                    class="mb-3">|| JAI MATA DI ||</p>
                                <div class="d-flex align-items-center gap-3">
                                    <img src="../../../../assets/images/png/phone.png" style="width:25px" width="25" />
                                    <div>
                                        <p style="font-size: 10px; font-weight: bold; margin: 0; white-space: pre;">
                                            9999205511</p>
                                        <p style="font-size: 10px; font-weight: bold; margin: 0; white-space: pre;">
                                            9999705511</p>
                                        <p style="font-size: 10px; font-weight: bold; margin: 0; white-space: pre;">
                                            9999405511</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table class="lavel1 " style="page-break-after: always;">
                        <tr>
                            <td>
                                <table class="lavel2">
                                    <tr>
                                        <td>
                                            <div class="schuleof mb-1">
                                                <h4 class="head2">SCHEDULE OF DEMURRAGE</h4>
                                                <P>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia amet
                                                    ex</P>
                                            </div>
                                            <div class="schuleof">
                                                <h4 class="head2">NOTICE</h4>
                                                <P class="mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                    Ad minus ducimus vel doloribus excepturi, possimus rem libero
                                                    veniam, similique architecto voluptate quas rerum tenetur laborum, a
                                                    fuga optio ipsum explicabo.</P>
                                            </div>
                                        </td>
                                        <td style="padding:0 10px;">
                                            <h1 class="consCOpy pt-0">CONSIGNEE COPY</h1>
                                            <h1 class="consCOpy atrisk">At Owner's Risk</h1>
                                            <h2 class="insurance">INSURANCE</h2>
                                            <div class="insuranceBox">
                                                <h3>The Customar has stated that:</h3>
                                                <p>&bull; He has not insured the consignment</p>
                                                <p>&nbsp; Or</p>
                                                <p>&bull; He has insured the consignment</p>
                                                <table class="insuranceText">
                                                    <tr>
                                                        <td colspan="2">
                                                            <div class="d-flex w-100">
                                                                Company <input type="text" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="d-flex w-100">
                                                                Policy&nbsp;No. <input style="width:165px"
                                                                    type="text" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex w-100">
                                                                Date <input type="text" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="d-flex w-100">
                                                                Amount <input style="width:165px" type="text" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex w-100">
                                                                Date <input type="text" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>

                                            </div>
                                        </td>
                                        <td></td>

                                    </tr>
                                    <tr>
                                        <td style="padding:15px 10px 15px 0" colspan="2">
                                            <div class="invoiceDetailsNew">Consignor's&nbsp;Name&nbsp;&&nbsp;Address
                                                <input type="text" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding:15px 10px 15px 0" colspan="2">
                                            <div class="invoiceDetailsNew"><input type="text" />GST <input
                                                    type="text" /> </div>
                                        </td>
                                    </tr>

                                </table>
                            </td>
                            <td>
                                <div class="coutionBox">
                                    <p class="text-center head2 ">COUTION</p>
                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat excepturi,
                                        illum quibusdam quo unde facere voluptas voluptatibus deserunt corporis aliquam
                                        aut quam optio alias. Accusamus ab sit beatae fugiat dolor?</p>
                                </div>
                                <div class="addressofdelivery">
                                    <h1 class="head2">Address of Delivery Office</h1>
                                    <input type="text" name="" id="">
                                    <div class="vehicleNumver head2">Vehicle No.</div>
                                </div>
                                <div class="consignmentNot">
                                    <h1 class="head2 text-center">CONSIGNMENT NOTE</h1>
                                    <h2 class="consignNum">5663</h2>
                                    <div class="invoiceDetailsNew">Date <input type="text" /> </div>
                                </div>

                            </td>
                        </tr>

                    </table>
                    <table class="lavel1">
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td style="padding:15px 10px 15px 0" colspan="2">
                                            <div class="invoiceDetailsNew">
                                                Consignee&nbsp;Bank's&nbsp;Name&nbsp;&&nbsp;Address <input
                                                    type="text" /> </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding:15px 10px 15px 0" colspan="2">
                                            <div class="invoiceDetailsNew"><input type="text" />GST <input
                                                    type="text" /> </div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <div class="fromtoBox">
                                    <p>From</p>
                                    <input type="text" />
                                    <p>To</p>
                                    <input type="text" />
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table class="lavel3">
                        <thead>
                            <tr>
                                <td rowspan="2">Packages</td>
                                <td rowspan="2">Methode of Packaging</td>
                                <td rowspan="2">Discription (Said to Contain)</td>
                                <td colspan="2">Weigth In KG</td>
                                <td rowspan="2">Rate</td>
                                <td>Frieght</td>
                                <td colspan="2">Amount</td>

                            </tr>
                            <tr>


                                <td>Actual</td>
                                <td>Charges</td>
                                <td>Frieght</td>
                                <td>Rs.</td>
                                <td>P.</td>
                            </tr>
                        </thead>

                        <tr>
                            <td>Packages</td>
                            <td>Methode of Packaging</td>
                            <td>Discription (Said to Contain)</td>
                            <td style="border-right-color: transparent;" class="borderbottom">Weigth In KG</td>
                            <td style="border-left-color: transparent;" class="borderbottom">Weigth In KG</td>
                            <td class="borderbottom">Rate</td>
                            <td class="borderbottom">Sercharge</td>
                            <td class="borderbottom">Amount</td>
                            <td class="borderbottom">Amount</td>
                        </tr>
                        <tr>
                            <td>Packages</td>
                            <td>Methode of Packaging</td>
                            <td>Discription (Said to Contain)</td>
                            <td style="border-right-color: transparent;" class="borderbottom">Weigth In KG</td>
                            <td style="border-left-color: transparent;" class="borderbottom">Weigth In KG</td>
                            <td class="borderbottom">Rate</td>
                            <td class="borderbottom">Cover Charges</td>
                            <td class="borderbottom">Amount</td>
                            <td class="borderbottom">Amount</td>
                        </tr>
                        <tr>
                            <td>Packages</td>
                            <td>Methode of Packaging</td>
                            <td>Discription (Said to Contain)</td>
                            <td style="border: 0;">Weigth In KG</td>
                            <td style="border:0">Weigth In KG</td>
                            <td class="borderbottom">Rate</td>
                            <td class="borderbottom">Hamali CHarges</td>
                            <td class="borderbottom">Amount</td>
                            <td class="borderbottom">Amount</td>
                        </tr>
                        <tr>
                            <td>Packages</td>
                            <td>Methode of Packaging</td>
                            <td>Discription (Said to Contain)</td>
                            <td style="border: 0;">Weigth In KG</td>
                            <td style="border:0">Weigth In KG</td>
                            <td style="border:0">Rate</td>
                            <td class="borderbottom">Door Del Charges</td>
                            <td class="borderbottom">Amount</td>
                            <td class="borderbottom">Amount</td>
                        </tr>
                        <tr>
                            <td>Packages</td>
                            <td>Methode of Packaging</td>
                            <td>Discription (Said to Contain)</td>
                            <td style="border: 0;">Weigth In KG</td>
                            <td style="border:0">Weigth In KG</td>
                            <td style="border:0">Rate</td>
                            <td class="borderbottom">Dilty Charges</td>
                            <td class="borderbottom">Amount</td>
                            <td class="borderbottom">Amount</td>
                        </tr>
                        <tr>
                            <td>Packages</td>
                            <td>Methode of Packaging</td>
                            <td>Discription (Said to Contain)</td>
                            <td style="border: 0;">Weigth In KG</td>
                            <td style="border:0">Weigth In KG</td>
                            <td style="border:0">Rate</td>
                            <td class="borderbottom">Bahti Charges</td>
                            <td class="borderbottom">Amount</td>
                            <td class="borderbottom">Amount</td>
                        </tr>
                        <tr>
                            <td>Packages</td>
                            <td>Methode of Packaging</td>
                            <td>Discription (Said to Contain)</td>
                            <td style="border: 0;">Weigth In KG</td>
                            <td style="border:0">Weigth In KG</td>
                            <td style="border:0">Rate</td>
                            <td class="borderbottom">Misc Charges</td>
                            <td class="borderbottom">Amount</td>
                            <td class="borderbottom">Amount</td>
                        </tr>
                        <tr>
                            <td>Packages</td>
                            <td>Methode of Packaging</td>
                            <td>Discription (Said to Contain)</td>
                            <td style="border: 0;">Weigth In KG</td>
                            <td style="border:0">Weigth In KG</td>
                            <td style="border:0">Rate</td>
                            <td>Total Rs.</td>
                            <td>Amount</td>
                            <td>Amount</td>
                        </tr>
                    </table>
                    <table class="lavel4">
                        <tr>
                            <td>
                                <div class="invoiceDetailsNew">Value&nbsp;Rs. <input
                                        type="text" />Basis&nbsp;of&nbsp;Booking </div>
                            </td>
                            <td>
                                <div class="invoiceDetailsNew justify-content-center align-items-center gap-3">(1) To
                                    Pay (2) Paid (3) to be Filled at
                                    <div class="topaidBox">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>

                                    </div>
                                </div>
                                <div class="invoiceDetailsNew justify-content-center align-items-center gap-3">Not
                                    Responible for Leakage, Breakage and Damages</div>
                            </td>
                            <td>
                                <div class="invoiceDetailsNew justify-content-center">Signature of Booking Officer
                                </div>
                            </td>

                        </tr>
                    </table>
                </div>
                <div class="invoicetabl" id="print-section" hidden>

                    <div class="inner">
                        <div class="leftbox">
                            <p class="gstno">GSTIN: 07AAPFJ8364B1Z5 </p>
                            <p class="panno">PANNO: AAPFJ8384B </p>

                            <div class="adresspanel">

                                <div class="logopanel">
                                    <img src="../../../assets/images/profilephoto.jpg" alt="">
                                </div>
                                <div class="addressbox">
                                    <h1>JAI SHANKAR CARGO MOVERS</h1>
                                    <h4>Fleet Owners & Transport Contractor</h4>
                                    <h4>Special Service For Bengal(All Over India)</h4>
                                    <p>Delhi Office: AS-23,</p>
                                    <p>Sanjay Gandhi Transport Nagar, Delhi: Pin Code:110042</p>
                                    <p>Ph.9999205511,9999705511,9999405511: , e-mail:shreesht.khuller@yahoo.com,
                                        care&#64;transport.com</p>
                                </div>

                            </div>
                            <div class="inputbox1">
                                <div class="consinorbox">
                                    <div class="input1">
                                        <label for="">Consignor Name & Address</label>
                                        <input type="text" disabled value="{{ particlarLR?.consignor_name }}">
                                    </div>
                                    <div class="input2">
                                        <!-- <input type="text" disabled value="{{ particlarLR?.consignor_address }}"> -->
                                        <p>{{ particlarLR?.consignor_address }}</p>
                                    </div>
                                </div>
                                <div class="consinorbox">
                                    <div class="input1">
                                        <label for="">Consignee Name & Address</label>
                                        <input type="text" disabled value="{{ particlarLR?.consignee_name }}">
                                    </div>
                                    <div class="input2">
                                        <p>{{ particlarLR?.consignee_address }}</p>
                                        <!-- <input type="text" disabled value="{{ particlarLR?.consignee_address }}"> -->
                                    </div>
                                </div>
                                <div class="inputbox3">
                                    <div class="input1">
                                        <label for="">GSTIN </label>

                                        <input type="text" disabled value="{{ particlarLR?.GSTIN }}">
                                    </div>
                                    <div class="input1">
                                        <label for="">State Code</label>
                                        <input type="text" disabled value="{{ particlarLR?.stateCode }}">
                                    </div>
                                </div>
                                <div class="inputbox3">
                                    <!-- <div class="input1">
                                        <label for="">DLY </label>
                                        <input type="text" disabled value="{{ particlarLR?.dly_no }}">
                                    </div>
                                    <div class="input1">
                                        <label for="">Declared Value Rs.</label>
                                        <input type="text" disabled value="{{ particlarLR?.declared_value }}">
                                    </div> -->
                                </div>
                            </div>
                            <div class="inpoicedetails">
                                <table>
                                    <thead>
                                        <tr>
                                            <td>No. of Articles</td>
                                            <td class="withinv">Customer Name </td>
                                            <td class="withinv">Invoice No.</td>
                                            <td>Value&nbsp;(&#8377;)</td>
                                            <td>Weight&nbsp;(Kg)</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let inv of particlarLR?.invoice_no ">

                                            <td>{{inv.NoofArticle}}</td>
                                            <td class="withinv">{{inv.customerName}}</td>
                                            <td>{{inv.InvoiceNo}}</td>
                                            <td>{{inv.InvoiceValue}}</td>
                                            <td>{{inv.Weight}}</td>
                                        </tr>

                                    </tbody>
                                    <tfoot>
                                        <!-- <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
            
                                            <!- <td class="withinv" style="border-bottom: none;">
                                                <div class="input1">
                                                    <label for="">DLY</label>
                                                    <input type="text" disabled value="{{particlarLR?.dly_no}}">
                                                </div>
                                            </td> --
                                            <td>&nbsp;</td>
                                            <td style="border-top:1px solid #000;" colspan="2">Frieght : {{ particlarLR?.Frieght }}
                                            </td>
            
                                        </tr> -->
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>Total</td>
                                            <!-- <td class="withinv" style="border-top: none;">
                                                <div class="input1">
                                                    <label for="">Declared Value Rs.</label>
                                                    <input type="text" disabled value="{{particlarLR?.declared_value}}">
                                                </div>
                                            </td> -->
                                            <td>&nbsp;</td>
                                            <td>{{ particlarLR?.declared_value}}</td>
                                            <td>{{getTotalWeight(particlarLR?.invoice_no)}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3"></td>
                                            <td colspan="2">
                                                Frieght&nbsp;:&nbsp;{{ particlarLR?.Frieght }}
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                            <td colspan="6" class="text-center">GST Paid by Consignor</td>
                                        </tr> -->
                                    </tfoot>

                                </table>
                            </div>

                        </div>
                        <div class="rightbox">
                            <div class="rightdetailbox">
                                <div class="rightinnbx">
                                    <p>Vehicle No.</p>
                                    <div class="trkno"> {{ particlarLR?.vehicle_id }}</div>
                                </div>
                            </div>
                            <div class="rightdetailbox">
                                <div class="rightinnbx">
                                    <p>Consignment Note</p>
                                    <div class="trkno"> {{ particlarLR?.consigment_note }}</div>
                                </div>
                                <div class="rightinnbx">
                                    <p>System LR No.</p>
                                    <div class="trkno"> {{ particlarLR?.LR_no }}</div>
                                </div>
                                <div class="rightinnbx">
                                    <p>Manual LR No.</p>
                                    <div class="trkno"> {{ particlarLR?.manualLR_no }}</div>
                                </div>
                            </div>
                            <div class="rightdetailbox">
                                <div class="rightinnbx">
                                    <p>Dated</p>
                                    <div class="trkno"> {{ particlarLR?.date | date:'yyyy-MM-dd' }}</div>
                                </div>
                            </div>
                            <div class="rightdetailbox">
                                <div class="rightinnbx">
                                    <p>From</p>
                                    <div class="trkno"> {{ particlarLR?.from }}</div>
                                </div>
                            </div>
                            <div class="rightdetailbox">
                                <div class="rightinnbx">
                                    <p>To</p>
                                    <div class="trkno"> {{ particlarLR?.to }}</div>
                                </div>
                            </div>
                            <div class="rightdetailbox">
                                <div class="rightinnbx">
                                    <p>E-way bill number</p>
                                    <div class="trkno"> {{ particlarLR?.road_permit_no }}</div>
                                </div>
                            </div>
                            <!-- <div class="righttblbox">
                                <table>
                                    <thead>
                                        <tr>
                                            <td colspan="3">Branch Offices</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Office </td>
                                            <td>: 0120-4108268</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp; </td>
                                            <td>: 9311074287</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp; </td>
                                            <td>: 7835005355</td>
                                        </tr>
                                        <tr>
                                            <td>U.P. Border </td>
                                            <td>: 9311074271</td>
                                        </tr>
                                        <tr>
                                            <td>Mundka (Delhi)</td>
                                            <td>: 9311074267</td>
                                        </tr>
                                        <tr>
                                            <td>Shahdra (Delhi)</td>
                                            <td>: 9311074286</td>
                                        </tr>

                                        <tr>
                                            <td>Punjab </td>
                                            <td>: 9056191488</td>
                                        </tr>

                                        <tr>
                                            <td>Amritsar</td>
                                            <td>: 7508864941</td>
                                        </tr>
                                        <tr>
                                            <td>Gurgaon</td>
                                            <td>: 7835005354</td>
                                        </tr>

                                        <tr>
                                            <td>Lucknow</td>
                                            <td>: 9935572471</td>
                                        </tr>
                                        <tr>
                                            <td>Patna </td>
                                            <td>: 8968637225</td>
                                        </tr>
                                        <tr>
                                            <td>Kasna </td>
                                            <td>: 9311074290</td>
                                        </tr>
                                        <tr>
                                            <td>Delhi NCR </td>
                                            <td>: 7009373634</td>
                                        </tr>
                                        <tr>
                                            <td>Samalkha (Delhi)</td>
                                            <td>: 9915514317</td>
                                        </tr>
                                        <tr>
                                            <td>South X </td>
                                            <td>: 8639876120</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->
                            <div class="rightfoot">
                                <!-- <div class="input1">
                                    <label for="">Vehicle Name:</label>
                                    <input type="text" disabled value="{{ vehiclesDetail?.vehicle_name }} {{ vehiclesDetail?.type }}" >
                                </div> -->
                                <div class="input1">
                                    <label for="">Start reading</label>
                                    <input type="text" disabled value="{{ particlarLR?.startmeterReading }}">
                                </div>
                                <div class="input1">
                                    <label for="">End reading</label>
                                    <input type="text" disabled value="{{ particlarLR?.endmeterReading }}">
                                </div>
                                <div class="input1">
                                    <label for="">UP/DOWN Km.</label>
                                    <input type="text" disabled value="{{ particlarLR?.up_down_km }}">
                                </div>
                                <div class="inputbox3">
                                    <div class="input1">
                                        <label for="">Out Time </label>
                                        <input type="text" disabled value="{{ particlarLR?.out_time }}">
                                    </div>
                                    <div class="input1">
                                        <label for="">In Time</label>
                                        <input type="text" disabled value="{{ particlarLR?.in_time }}">
                                    </div>
                                </div>
                                <div class="input1">
                                    <label for="">Total Hours</label>
                                    <input type="text" disabled
                                        value="{{getTotalHrs( particlarLR?.totalhour, particlarLR?.extrahour)}}">
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="footerpanel">
                        <div class="footerleft">
                            <div class="footbox1">
                                <p>1. No. Re-Booking Arrengement.</p>
                                <p>2. Destination octroi Paid by th Customer</p>
                                <p>3. Subject to Ghaziabad Jurisdiction only</p>
                            </div>
                            <div class="footbox1">
                                <p>At Owner's Risk</p>
                                <p>At Carrier's Risk</p>
                            </div>
                            <div class="footbox1">
                                <p>Company is not liable for Leakage/</p>
                                <p>Damage/Shortage during Accidents of lorry.</p>
                                <p>Company is not liable for leakage of Liquid material.</p>
                            </div>
                        </div>
                        <div class="footerright">
                            <h6>K LOGISTICS</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>