<div class="container-fluid" [ngSwitch]="checkInvoiceType">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View Invoice</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement">Manage LR</li>
                    <li class="breadcrumb-item active" aria-current="page">View Invoice </li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
            <div class="contentbox">
                <div class="text-center ">
                    <button mat-raised-button type="button" class="btn btn-outline-dark btn-sm px-4" ngxPrint
                        [useExistingCss]="true" printSectionId="print-section3">Print</button>&nbsp;
                    <button routerLink="../../gst-invoice/{{this.paramId}}" mat-raised-button
                        class="btn btn-dark btn-sm px-4">GST Invoice</button>&nbsp;
                    <button mat-raised-button class="btn btn-dark btn-sm px-4"
                        (click)="sendInvoice('print-section3')">Email</button>
                </div>
                <div class="row justify-content-center mt-3 ">
                    <div class="col-xl-10">
                        <div class="ahujatransport table-responsive" id="print-section3">
                            <table>
                                <tr>
                                    <td colspan="2" class="text-center">
                                        <h2>Ahuja Transport Company</h2>
                                        <p>Contact Office : H. No. 21, Sector - 15, Faridabad-121007, Haryana</p>
                                        <p>Tel:0129-4008140, (M) 9810412343, 9810065143</p>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p style="font-weight: bold;">To,</p>
                                        <p>Panasonic Life Solutions India Private Limited</p>
                                        <p>22/1, 46th Cross Road, V Block</p>
                                        <p>Jayanagar, Bangalore</p>
                                        <p>Karnataka-560011</p>
                                        <p>Contact person: Mr. Gopal Jha</p>
                                        <p>Contact No : 9650010746</p>
                                        <p>GSTIN : 29AAECA2190C2ZY</p>

                                    </td>
                                    <td style="width:43%">
                                        <table class="AddCont">
                                            <tr>
                                                <td style="font-weight: bold;">Invoice No :</td>
                                                <td>{{this.sys_invoice}}</td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: bold;">Date :</td>
                                                <td>{{this.created_inv_date | date: 'dd-MM-yyyy'}}</td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: bold;">Service :</td>
                                                <td>Goods Transport Agency service road transport </td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: bold;">SAC Code :</td>
                                                <td>996511 </td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: bold;">Reserse Charge Applicable :</td>
                                                <td>No </td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: bold;">GSTIN :</td>
                                                <td>06AAEPA0775C1ZD</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <table class="freightTable mt-3">
                                            <thead>
                                                <tr>
                                                    <td colspan="16">
                                                        <h4 class="mb-0">Freight Bills Details</h4>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>S. <br /> No.</td>
                                                    <td>Manual LR<br /> No.</td>
                                                    <td>Invoice <br />Date</td>
                                                    <td>LR/GR <br /> No.</td>
                                                    <td>LR/GR <br /> Date</td>
                                                    <td>From</td>
                                                    <td>To</td>
                                                    <td>Vehicle <br /> No.</td>
                                                    <td>Truck <br /> Size</td>
                                                    <td>Freight</td>
                                                    <td>Detention</td>
                                                    <td class="width100">Other Charges (Hold, Unloading, etc)</td>
                                                    <td>Incentive</td>
                                                    <td class="width100">In-Transit Damage Deduction @1pc * Rs.300</td>
                                                    <td>Total</td>
                                                    <td class="width150">Remarks</td>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let user of this.details; let j = index">
                                                <tr *ngFor=" let a of user.LR_no ; let i = index">
                                                    <td>{{i+1}}</td>
                                                    <td>{{user.manualLR_no[i]}}</td>
                                                    <td class="text-nowrap">{{this.for_created_date |
                                                        date:'dd-MM-yyyy'}}
                                                    </td>
                                                    <td>{{user.LR_no[i]}}</td>
                                                    <td class="text-nowrap">{{user.created_date[i] | date:'dd-MM-yyyy'}}
                                                    </td>
                                                    <td>{{user.from[i]}}</td>
                                                    <td>{{user.to[i]}}</td>
                                                    <td>{{user.vehicle_id[i]}}</td>
                                                    <td>N/A</td>
                                                    <td>{{user.Frieght[i]}}</td>
                                                    <td>{{user.detention[i]}}</td>
                                                    <td class="width100"></td>
                                                    <td>N/A</td>
                                                    <td class="width100">N/A</td>
                                                    <td>{{user.detention[i] + user.total_value[i]}}</td>
                                                    <td class="width150">N/A</td>


                                                </tr>
                                                <tr>
                                                    <td colspan="8" rowspan="6">
                                                        <p class="fw-semibold">Our PAN No. AAEPA0775C</p>
                                                        <p class="fw-semibold">Our GST No. 06AAEPA0775C1ZD</p>
                                                    </td>

                                                    <td colspan="4"><b>Sub Total</b></td>

                                                    <td>&nbsp;</td>
                                                    <td class="width100">&nbsp;</td>
                                                    <td><b>{{this.total_value_detention
                                                            }}</b></td>
                                                    <td class="width150">N/A</td>

                                                </tr>
                                                <tr>
                                                    <td colspan="4">CGST@6%</td>
                                                    <td>&nbsp;</td>
                                                    <td class="width100">&nbsp;</td>
                                                    <td>{{"-"}}</td>
                                                    <td class="width150">N/A</td>

                                                </tr>
                                                <tr>
                                                    <td colspan="4">SGST@6%</td>
                                                    <td>&nbsp;</td>
                                                    <td class="width100">&nbsp;</td>
                                                    <td>{{"-"}}</td>
                                                    <td class="width150">N/A</td>

                                                </tr>
                                                <tr>
                                                    <td colspan="4">IGST@12</td>
                                                    <td>&nbsp;</td>
                                                    <td class="width100">&nbsp;</td>
                                                    <td>{{this.totalGstSstSum | number: '1.2-2'}}</td>
                                                    <td class="width150">N/A</td>

                                                </tr>
                                                <tr>
                                                    <td colspan="4">Round Off (+/-)</td>
                                                    <td>&nbsp;</td>
                                                    <td class="width100">&nbsp;</td>
                                                    <td>-</td>
                                                    <td class="width150">N/A</td>

                                                </tr>
                                                <tr>
                                                    <td colspan="4">Total Rs.</td>
                                                    <td>&nbsp;</td>
                                                    <td class="width100">&nbsp;</td>
                                                    <td>{{this.totalInvValueWithGST | number: '1.2-2'}}</td>
                                                    <td class="width150">N/A</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3 d-none">
        <div class="col-xl-12" *ngSwitchCase="'Type1'">
            <div class="contentbox">
                <div class="text-center ">

                    <button mat-raised-button type="button" class="btn btn-outline-dark btn-sm px-4"
                        printSectionId="print-section">Print</button>&nbsp;
                    <button routerLink="../../gst-invoice/{{this.paramId}}" mat-raised-button
                        class="btn btn-dark btn-sm px-4">GST Invoice</button>&nbsp;
                    <button mat-raised-button class="btn btn-dark btn-sm px-4"
                        (click)="sendInvoice('print-section')">Email</button>
                </div>
                <div class="NewInvoice table-responsive" id="print-section">
                    <table class="newInvoiceBox">
                        <tr>
                            <td class="py-3">
                                <table class="invoiceHead">
                                    <tr>
                                        <td>Transporter Name </td>
                                        <td>: K LOGISTICS</td>
                                    </tr>
                                    <!-- <tr>
                                        <td>Location</td>
                                        <td>: Shahdara</td>
                                    </tr>
                                    <tr>
                                        <td>Type of Vehicle </td>
                                        <td>: ECCO</td>
                                    </tr>
                                    <tr>
                                        <td>Vehicle No</td>
                                        <td>: DL1V7320</td>
                                    </tr> -->
                                </table>
                            </td>
                            <td class="text-end py-3">
                                <img src="../../../../assets/images/invoiceLogoDemo.png" width="200" alt="">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="invoiceDetails" *ngIf='users.length > 0'>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>System LR No.</td>
                                            <td>Manual LR No.</td>
                                            <td>VEHICLE NO:</td>
                                            <td>From</td>
                                            <td>TO</td>
                                            <td>START READING</td>
                                            <td>END.READING</td>
                                            <td>KMR.</td>
                                            <td>EXTRA HRS</td>
                                            <td>Toll & Parking</td>
                                            <td>AMOUNT</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let user of users; let j=index">
                                        <tr *ngFor="let j of user.LR_no;let i=index">
                                            <td>{{user.date[i] | date }}</td>
                                            <td>{{user.LR_no[i] }}</td>
                                            <td>{{user.manualLR_no[i] }}</td>
                                            <td>{{user.vehicle_id[i] }}</td>
                                            <td>{{user.from[i] }}</td>
                                            <td>{{user.to[i] }}</td>
                                            <td>{{user.startmeterReading[i] }}</td>
                                            <td>{{user.endmeterReading[i] }}</td>
                                            <td>{{user.up_down_km[i] }}</td>
                                            <td>{{user.extrahour[i] }} </td>
                                            <td>{{user.tooltaxPaid[i] }}</td>
                                            <td>{{user.vehicleRate[i] }}</td>
                                        </tr>

                                        <tr>
                                            <td class="footer">TOTAL</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">{{this.kmtot | number:'1.2-2'}}</td>
                                            <td class="footer">{{this.extrahour | number:'1.2-2'}}</td>
                                            <td class="footer">{{this.tooltaxPaidtot | number:'1.2-2'}}</td>
                                            <td class="footer">{{this.invtot | number:'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8"></td>
                                            <td colspan="3" class="p-0 ">
                                                <table class="extraText">
                                                    <tr>
                                                        <td>Total KM={{this.kmtot}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Extra Hours={{this.extrahour}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Toll & Parking={{this.tooltaxPaidtot}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total={{this.invtot| number:'1.2-2'}}</td>
                                                    </tr>
                                                </table>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="11"><b>{{this.invtot | amountInWords}}
                                                </b></td>
                                        </tr>
                                    </tbody>

                                </table>
                                <table class="invoiceDetails" hidden>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>Opening Reading </td>
                                            <td>Closing Reading</td>
                                            <td>Total KM</td>
                                            <td>Start Time</td>
                                            <td>End Time </td>
                                            <td>Total Hours</td>
                                            <td>Destination/Route</td>
                                            <td>Toll </td>
                                            <td> Parking</td>
                                            <td>Value of Total Invoices</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01.11.2019</td>
                                            <td>165690</td>
                                            <td>165732</td>
                                            <td>42</td>
                                            <td>06.00AM</td>
                                            <td>12.00PM </td>
                                            <td>&nbsp;</td>
                                            <td>CANNAUGHT PLACE</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>12573</td>
                                        </tr>
                                        <tr>
                                            <td>01.11.2019</td>
                                            <td>165690</td>
                                            <td>165732</td>
                                            <td>42</td>
                                            <td>06.00AM</td>
                                            <td>12.00PM </td>
                                            <td>6</td>
                                            <td>CANNAUGHT PLACE</td>
                                            <td>230</td>
                                            <td>&nbsp;</td>
                                            <td>12573</td>
                                        </tr>
                                        <tr>
                                            <td>01.11.2019</td>
                                            <td>165690</td>
                                            <td>165732</td>
                                            <td>42</td>
                                            <td>06.00AM</td>
                                            <td>12.00PM </td>
                                            <td>&nbsp;</td>
                                            <td>CANNAUGHT PLACE</td>
                                            <td>&nbsp;</td>
                                            <td>40</td>
                                            <td>12573</td>
                                        </tr>
                                        <tr>
                                            <td class="footer">TOTAL</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">3997</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">65</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">2370</td>
                                            <td class="footer">1700</td>
                                            <td class="footer">2182563</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <p>We hereby confirmed that all the LR copies duly acknowledged by the customers have
                                    been received and above details are found correct.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="signature">
                                    <tr>
                                        <td>
                                            <p>Prepaired By</p>
                                            <p>Name</p>
                                            <p>Designation</p>
                                        </td>
                                        <td>
                                            <p>Verified By</p>
                                            <p>Name</p>
                                            <p>Designation</p>
                                        </td>
                                        <td>
                                            <p>Approved By</p>
                                            <p>Name</p>
                                            <p>Designation</p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>


    <div class="row mt-3 d-none" *ngSwitchCase="'Type2'">
        <div class="col-xl-12">
            <div class="contentbox">
                <div class="text-center ">

                    <button mat-raised-button class="btn btn-outline-dark btn-sm px-4"
                        printSectionId="print-section">Print</button>&nbsp;
                    <button routerLink="../../gst-invoice/{{this.paramId}}" mat-raised-button
                        class="btn btn-dark btn-sm px-4">GST Invoice</button>&nbsp;
                    <button mat-raised-button class="btn btn-dark btn-sm px-4"
                        (click)="sendInvoice('print-section')">Email</button>
                </div>
                <div class="NewInvoice table-responsive" id="print-section">
                    <table class="newInvoiceBox">
                        <tr>
                            <td class="py-3">
                                <table class="invoiceHead">
                                    <tr>
                                        <td>Transporter Name </td>
                                        <td>: Whirlpool PVT LTD</td>
                                    </tr>
                                    <!-- <tr>
                                        <td>Location</td>
                                        <td>: Shahdara</td>
                                    </tr>
                                    <tr>
                                        <td>Type of Vehicle </td>
                                        <td>: ECCO</td>
                                    </tr>
                                    <tr>
                                        <td>Vehicle No</td>
                                        <td>: DL1V7320</td>
                                    </tr> -->
                                </table>
                            </td>
                            <td class="text-end py-3">
                                <img src="../../../../assets/images/invoiceLogoDemo.png" width="200" alt="">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="invoiceDetails" *ngIf='users.length > 0'>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>System LR No.</td>
                                            <td>Manual LR No.</td>
                                            <td>VEHICLE NO:</td>
                                            <td>From</td>
                                            <td>TO</td>
                                            <td>START READING</td>
                                            <td>END.READING</td>
                                            <td>KMR.</td>
                                            <td>EXTRA HRS</td>
                                            <td>Toll & Parking</td>
                                            <td>AMOUNT</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let user of users; let j=index">
                                        <tr *ngFor="let j of user.LR_no;let i=index">
                                            <td>{{user.date[i] | date }}</td>
                                            <td>{{user.LR_no[i] }}</td>
                                            <td>{{user.manualLR_no[i] }}</td>
                                            <td>{{user.vehicle_id[i] }}</td>
                                            <td>{{user.from[i] }}</td>
                                            <td>{{user.to[i] }}</td>
                                            <td>{{user.startmeterReading[i] }}</td>
                                            <td>{{user.endmeterReading[i] }}</td>
                                            <td>{{user.up_down_km[i] }}</td>
                                            <td>{{user.extrahour[i] }} </td>
                                            <td>{{user.tooltaxPaid[i] }}</td>
                                            <td>{{user.vehicleRate[i] }}</td>
                                        </tr>

                                        <tr>
                                            <td class="footer">TOTAL</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">{{this.kmtot | number:'1.2-2'}}</td>
                                            <td class="footer">{{this.extrahour | number:'1.2-2'}}</td>
                                            <td class="footer">{{this.tooltaxPaidtot | number:'1.2-2'}}</td>
                                            <td class="footer">{{this.invtot | number:'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8"></td>
                                            <td colspan="3" class="p-0 ">
                                                <table class="extraText">
                                                    <tr>
                                                        <td>Total KM={{this.kmtot}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Extra Hours={{this.extrahour}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Toll & Parking={{this.tooltaxPaidtot}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total={{this.invtot| number:'1.2-2'}}</td>
                                                    </tr>
                                                </table>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="11"><b>{{this.invtot | amountInWords}}
                                                </b></td>
                                        </tr>
                                    </tbody>

                                </table>
                                <table class="invoiceDetails" hidden>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>Opening Reading </td>
                                            <td>Closing Reading</td>
                                            <td>Total KM</td>
                                            <td>Start Time</td>
                                            <td>End Time </td>
                                            <td>Total Hours</td>
                                            <td>Destination/Route</td>
                                            <td>Toll </td>
                                            <td> Parking</td>
                                            <td>Value of Total Invoices</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01.11.2019</td>
                                            <td>165690</td>
                                            <td>165732</td>
                                            <td>42</td>
                                            <td>06.00AM</td>
                                            <td>12.00PM </td>
                                            <td>&nbsp;</td>
                                            <td>CANNAUGHT PLACE</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>12573</td>
                                        </tr>
                                        <tr>
                                            <td>01.11.2019</td>
                                            <td>165690</td>
                                            <td>165732</td>
                                            <td>42</td>
                                            <td>06.00AM</td>
                                            <td>12.00PM </td>
                                            <td>6</td>
                                            <td>CANNAUGHT PLACE</td>
                                            <td>230</td>
                                            <td>&nbsp;</td>
                                            <td>12573</td>
                                        </tr>
                                        <tr>
                                            <td>01.11.2019</td>
                                            <td>165690</td>
                                            <td>165732</td>
                                            <td>42</td>
                                            <td>06.00AM</td>
                                            <td>12.00PM </td>
                                            <td>&nbsp;</td>
                                            <td>CANNAUGHT PLACE</td>
                                            <td>&nbsp;</td>
                                            <td>40</td>
                                            <td>12573</td>
                                        </tr>
                                        <tr>
                                            <td class="footer">TOTAL</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">3997</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">65</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">2370</td>
                                            <td class="footer">1700</td>
                                            <td class="footer">2182563</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <p>We hereby confirmed that all the LR copies duly acknowledged by the customers have
                                    been received and above details are found correct.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="signature">
                                    <tr>
                                        <td>
                                            <p>Prepaired By</p>
                                            <p>Name</p>
                                            <p>Designation</p>
                                        </td>
                                        <td>
                                            <p>Verified By</p>
                                            <p>Name</p>
                                            <p>Designation</p>
                                        </td>
                                        <td>
                                            <p>Approved By</p>
                                            <p>Name</p>
                                            <p>Designation</p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>