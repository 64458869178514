import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-monthly-vehicle',
  templateUrl: './monthly-vehicle.component.html',
  styleUrls: ['./monthly-vehicle.component.css']
})
export class MonthlyVehicleComponent implements OnInit {


  monthArr: any = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  monthArrFull: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  paramId: any;
  month: any;
  vehicleExpenseArray: any = [];
  userSessionData: any = JSON.parse(sessionStorage.getItem('userSession'));
  myArr: any;
  expenseArr: any = [];
  year: any;
  dtOptions: any = {};
  vcount: any;
  vvcount: {};
  removeDuplicates: any;
  lrCount: any;
  count: any = []

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService, private superadminService: SuperAdminService, private spinner: NgxSpinnerService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.month;
      this.month = this.monthArrFull[this.monthArr.indexOf(this.paramId)];
      this.year = param.year;
      console.log("paramparam",param);
    })
  }


  ngOnInit(): void {
    this.getVehicleExpenseGroup()

    this.dtOptions = {
      dom: 'Bfrtip',

      buttons: [
        {
          extend: 'csv',
          text: 'Export'
        }

      ]
    }
  }

  getVehicleExpenseGroup() {
    this.spinner.show();
    this.superadminService.getVehicleExpenseParticularMonth()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.spinner.hide();
          this.superadminService.getClientList()
            .subscribe((client: { status: any, message: any, data: any }) => {
              if (client.status == true) {
                this.myArr = res.data;
                console.log("this.myArr", this.myArr);

                this.myArr = this.myArr.filter((item) => {
                  return item._id.year == this.year
                })

                if (this.userSessionData.user_role_id[0] != 1) {
                  this.myArr = this.myArr.filter((item) => {
                    return item._id.supervisorId == this.userSessionData._id
                  })
                }
                console.log("this.myArr", this.myArr);
                this.myArr = this.myArr.filter((item) => {
                  return this.monthArr[item._id.month - 1] == this.paramId
                })
                // for (let i = 0; i < this.myArr.length; i++) {
                //   this.expenseArr.push({ "supervisorId": this.myArr[i]._id.supervisorId, "companyId": this.myArr[i]._id.companyId, "VehicleID": this.myArr[i]._id.vehicleNo, "LR_no": this.myArr[i]._id.LR_no, "vehicleRate": this.myArr[i]._id.vehicleRate, "paymentDue": this.myArr[i]._id.paymentDue, "paymentReceived": this.myArr[i]._id.paymentReceived, "totalIncome": this.myArr[i]._id.totalIncome, "amount": this.myArr[i].amount, "total_value": this.myArr[i]._id.total_value, "total_vehicle_expense": this.myArr[i]._id.total_vehicle_expense, "payment_Due": this.myArr[i]._id.payment_Due })

                // }

                for (let i = 0; i < this.myArr.length; i++) {
                  // debugger;
                  this.expenseArr.push({ "supervisorId": this.myArr[i]._id.supervisorId, "companyId": this.myArr[i]._id.companyId, "TripID": this.myArr[i]._id.TripID, "Advance": this.myArr[i]._id.Advance, "amount": this.myArr[i].amount, "vehicleRate": this.myArr[i]._id.vehicleRate, "balanceDue": this.myArr[i]._id.balanceDue, "income": this.myArr[i]._id.totalIncome, "LR_no": this.myArr[i]._id.LR_no, "totalValue": this.myArr[i]._id.totalValue, "totalExpense": this.myArr[i]._id.totalExpense, "totalIncome": this.myArr[i]._id.totalIncome, "paymentDue": this.myArr[i]._id.paymentDue, "waitingCharge": this.myArr[i]._id.waitingCharge, "overLoadCharges": this.myArr[i]._id.overLoadCharges, "VehicleID": this.myArr[i]._id.vehicleNo, "total_value": this.myArr[i]._id.total_value, "total_vehicle_expense": this.myArr[i]._id.total_vehicle_expense, "paymentReceived": this.myArr[i]._id.paymentReceived, "payment_Due": this.myArr[i]._id.payment_Due })
                };
                console.log("this.expenseArr", this.expenseArr);
                this.expenseArr.forEach((x, index) => {
                  client.data.filter(y => {
                    if (y._id == x.companyId) this.expenseArr[index].companyName = y.clientName
                  })
                })
                this.superadminService.getSuperVisorName().subscribe((supervisors: { status: any, message: any, data: any }) => {
                  if (supervisors.status == true) {
                    var supervisorsData = supervisors.data;
                    this.expenseArr.forEach((item, i) => {
                      this.expenseArr[i].SupervisorName = supervisorsData.filter((item) => this.expenseArr[i].supervisorId == item._id);
                      this.expenseArr[i].SupervisorName = this.expenseArr[i].SupervisorName[0] ? this.expenseArr[i].SupervisorName[0]['full_name'] : this.expenseArr[i].SupervisorName[0]
                    });
                    this.lrCount = this.myArr.map((x: any) => x._id.TripID)
                    this.vcount = this.myArr.map((x: any) => x._id.vehicleNo)
                    console.log("this.vcount", this.vcount);

                    const counts = {};
                    this.vcount.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

                    this.vvcount = counts;

                    this.removeDuplicates = this.expenseArr.filter(({ TripID }, index) =>
                      !this.lrCount.includes(TripID, index + 1)
                    )

                    this.lrCount.forEach((x) => {

                      if (this.count[x]) {
                        this.count[x] += 1;

                      } else {
                        this.count[x] = 1;

                      }
                    })

                    let result = [];
                    debugger;
                    console.log("this.expenseArr5",this.expenseArr);
                    this.expenseArr.forEach(function (a) {
                      if (!this[a.VehicleID]) {
                        this[a.VehicleID] = { VehicleID: a.VehicleID, supervisorId: a.SupervisorName, totalValue: 0, vehicleRate: 0, waitingCharge: 0, totalExpense: 0, Advance: 0, totalIncome: 0, paymentDue: 0, vehicle_no: a.VehicleID, total_value: 0, total_vehicle_expense: 0, paymentReceived: 0 , payment_Due:0 };
                        result.push(this[a.VehicleID]);
                      }

                      this[a.VehicleID].vehicleRate += a.vehicleRate;
                      this[a.VehicleID].total_value += a.total_value;
                      this[a.VehicleID].payment_Due += a.payment_Due;
                      this[a.VehicleID].waitingCharge += a.waitingCharge;
                      this[a.VehicleID].totalExpense += a.totalExpense;
                      this[a.VehicleID].Advance += a.Advance;
                      this[a.VehicleID].totalIncome += a.totalIncome;
                      this[a.VehicleID].paymentDue += a.paymentDue;
                      this[a.VehicleID].paymentReceived += a.paymentReceived;
                      this[a.VehicleID].total_vehicle_expense += a.total_vehicle_expense;
                    }, Object.create(null));
                    console.log("result", result);
                    this.vehicleExpenseArray = result
                    console.log("vehicleExpenseArray", this.vehicleExpenseArray);


                    const map = new Map()


                    // this.vehicleExpenseArray = this.expenseArr;
                  } else {
                    // console.log(res.message);
                  }
                }, error => {
                  // console.log('error', error);
                })
              }
              else {
                if (res.status == 401) {
                  this.toastr.error(res.message);
                  window.sessionStorage.clear();
                  this.router.navigateByUrl('superAuth/login');

                } else {
                  this.toastr.error(res.message);
                }
              }

            }, error => {
            });
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      }
      )
  }



  backTo() {
    window.history.back()
  }

}
