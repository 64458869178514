import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';
import { AddInvoiceComponent } from '../add-invoice/add-invoice.component';
import { EditInvoiceComponent } from '../edit-invoice/edit-invoice.component';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { AddfuelExpenseComponent } from '../addfuel-expense/addfuel-expense.component';
import { AddDriverExpenseComponent } from '../add-driver-expense/add-driver-expense.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdvanceComponent } from '../advance/advance.component';
import { TruckExpenceComponent } from '../truck-expence/truck-expence.component';
import { OtherexpenceComponent } from '../otherexpence/otherexpence.component';

@Component({
  selector: 'app-detail-lr',
  templateUrl: './detail-lr.component.html',
  styleUrls: ['./detail-lr.component.css'],

})
export class DetailLrComponent implements OnInit {



  @Output() clearEvent = new EventEmitter<string>();

  selectedDate: string | null = null;
  final_filter_advance: any = [];
  final_filter_truck: any = [];
  final_filter_other: any = [];
  prev: any;
  isTab = 'fuelExpense';
  sumOfFuelExpense: any;
  sumOfDriverCash: any;
  Fuelsum: any;
  driverSum: any;
  fuelExpenseData: any;
  driverCashData: any;
  clientName: any;
  vehicles: any;
  InvoiceDetails = [];
  clientList = [];
  particlarLR: any;
  vehiclesDetail: any;
  paramId;
  imageURL: any
  previewLoadingSlipURL: any;
  apiUrl = environment.domain;
  @ViewChild('showLoadingSlipPreview') showLoadingSlipPreview: ElementRef;
  @ViewChild('paginator') paginator: MatPaginator;
  displayedColumns: any = ['Date', 'Fuel Quantity', 'Fuel Type', 'Amount', 'Drive Name', 'Action'];
  driverExpenseColumns: any = ['date', 'Amount Paid', 'Payment Mode', 'Drive Name', 'Action'];
  advanceExpenseColumns: string[] = ['date', 'advance_amount', 'description', 'payment_mode', 'action'];
  truckExpenseColumns: string[] = ['date', 'Expense_amount', 'Payment Mode', 'Truck Number', 'Description', 'Action'];

  otherExpenseColumns: string[] = ['date', 'Expense type', 'Expense_amount', 'Payment Mode', 'Description', 'Action'];


  fuelExpenseDataSource: MatTableDataSource<any>;
  dataSource2: MatTableDataSource<any>;
  dataSource3: MatTableDataSource<any>;
  advance_expenses: MatTableDataSource<any>;
  truck_expense: MatTableDataSource<any>;
  other_expense: MatTableDataSource<any>;
  editLR = new FormGroup({
    clientList: new FormControl(''),
    ConsigneeName: new FormControl(''),
    ConsigneeAddress: new FormControl(''),
    ConsignorName: new FormControl(''),
    ConsignorAddress: new FormControl(''),
    VehicleNo: new FormControl(''),
    vehicleType: new FormControl(''),
    from: new FormControl(''),
    to: new FormControl(''),
    no_Of_Packages: new FormControl(""),
    description: new FormControl(""),
    GSTIN: new FormControl(''),
    consignor_GSTIN: new FormControl(''),
    date: new FormControl(''),
    RoadPermitNo: new FormControl(''),
    StateCode: new FormControl(''),
    DLY: new FormControl(''),
    DeclaredValue: new FormControl(''),
    fixAdhoc: new FormControl(''),
    quantity: new FormControl(''),
    transportorName: new FormControl(''),
    transportorMobile: new FormControl(''),
    driverName: new FormControl(''),
    driverMobile: new FormControl(''),
    tollAttachment: new FormControl(''),
    loadingSlipAttchment: new FormControl(''),
    Frieght: new FormControl(''),


    startmeterReading: new FormControl(''),
    endmeterReading: new FormControl(''),
    tripSDate: new FormControl(''),
    tripEDate: new FormControl(''),
    outtime: new FormControl(''),
    INtime: new FormControl(''),
    noofTools: new FormControl(''),
    tooltaxPaid: new FormControl(''),
    labourPaid: new FormControl(''),
    otherPayment: new FormControl(''),
    updownkm: new FormControl(''),
    totalhour: new FormControl(''),
    extrahour: new FormControl(''),
    detention: new FormControl(''),
    advance: new FormControl(''),
    balance: new FormControl(''),
    balancePaid: new FormControl(''),
    vehicleRate: new FormControl(''),
    // clientList: new FormControl('', [Validators.required]),
    LR_no: new FormControl('', [Validators.required]),
    // ConsigneeName: new FormControl('', [Validators.required]),
    // ConsigneeAddress: new FormControl('', [Validators.required]),
    // ConsignorName: new FormControl('', Validators.required),
    // ConsignorAddress: new FormControl('', Validators.required),
    // VehicleNo: new FormControl('', [Validators.required]),
    // from: new FormControl('', Validators.required),
    // to: new FormControl('', Validators.required),
    // GSTIN: new FormControl('', [Validators.required]),
    // date: new FormControl('', Validators.required),

    // startmeterReading: new FormControl(''),
    // endmeterReading: new FormControl(''),
    // outtime: new FormControl(''),
    // INtime: new FormControl(''),
    // noofTools: new FormControl(''),
    // tooltaxPaid: new FormControl(''),
    // labourPaid: new FormControl(''),
    // otherPayment: new FormControl(''),
    // updownkm: new FormControl(''),
    // totalhour: new FormControl(''),
    // vehicleRate: new FormControl('')

    //status: new FormControl('', Validators.required)
  })
  clientNames: any;
  getadvanceExpenseofParticularLR_data: any;
  sumofadvanceexpense: any;
  advance_sum: any;
  ParticularadvanceExpense: any;
  all_advance_expensess: any;
  all_truck_expensess: any;
  gettruckExpenseofParticularLR_data: any;
  sumoftruckexpense: any;
  truck_sum: any;
  all_other_expensess: any;
  ParticularotherExpense: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public dialog: MatDialog, private toastr: ToastrService, private superAdminService: SuperAdminService, private sanitizer: DomSanitizer, private toaster: ToastrService, private spinner: NgxSpinnerService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getLRDetail(this.paramId);
      this.getfuelExpenseofParticularLR(this.paramId);
      this.getDriverCashofParticularLR(this.paramId);
      this.getParticularadvanceExpense(this.paramId);
      this.getadvanceExpenseofParticularLR(this.paramId);
      this.gettruckExpenseofParticularLR(this.paramId);
      this.getParticulartruckExpense(this.paramId);
      this.getParticularotherExpense(this.paramId);
      this.getotherExpenseofParticularLR(this.paramId);
    })
  }

  ngOnInit(): void {
    this.prev = this.apiUrl + '/' + this.particlarLR?.loadingSlipAttchment[0]
    // this.get_all_advance_expense();
    this.get_all_truck_expense();
    // this.get_all_other_expense();
  }

  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.clientList = rawdata;
          console.log("this.clientListthis.clientList", this.clientList)
          this.clientName = this.clientList.filter((item) => item._id == this.particlarLR.clientId)['clientName'];

          console.log("this.clientNamethis.clientName", this.clientName)


          //  debugger;   
          for (let i = 0; i < this.clientList.length; i++) {
            if (this.clientList[i]._id === this.particlarLR.clientId) {
              this.clientNames = this.clientList[i].clientName
            }
          }
          console.log("ck=lient NMW", this.clientNames)
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });

  }
  getLRDetail(id: string) {
    this.superAdminService.particlarLR(id).subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        this.particlarLR = res.data[0].receipts[0];
        console.log('LR', this.particlarLR);

        this.vehicles = res.data[0].vehiclesDetail;
        this.getClientList();
        this.superAdminService.getSuperVisorName().subscribe((supervisors: { status: any, message: any, data: any }) => {
          if (supervisors.status == true) {
            var supervisorsData = supervisors.data;
            this.particlarLR.supervisorId = supervisorsData.filter((item) => this.particlarLR.supervisorId == item._id);
            this.particlarLR.supervisorId = this.particlarLR.supervisorId[0] ? this.particlarLR.supervisorId[0]['full_name'] : this.particlarLR.supervisorId[0]
            // console.log(this.particlarLR.supervisorId);
          } else {
            // console.log(res.message);
          }
        }, error => {
          // console.log('error', error);
        })
        this.initform();
      }
      else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');

        } else {
          this.toastr.success(res.message);
        }
      }

    }, error => {
    });

  }

  getfuelExpenseofParticularLR(id: string) {
    this.spinner.show();
    this.superAdminService.getFuelExpenseofParticularLR(id).subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        let rawdata = res.data
        this.fuelExpenseData = rawdata
        // console.log("lr data of fuel expense", rawdata)
        this.sumOfFuelExpense = this.fuelExpenseData.map((x) => x.fuel_expense_amount)
        // console.log("lr data of fuel expense", this.sumOfFuelExpense )
        // console.log(this.sumOfFuelExpense)
        this.Fuelsum = this.sumOfFuelExpense.reduce((accumulator, currentValue) => {
          return accumulator + currentValue + this.particlarLR.diesel_expense
        }, 0);
        this.fuelExpenseDataSource = new MatTableDataSource(this.fuelExpenseData)
        this.spinner.hide()
      } else {
        this.toaster.error(res.message)
      }
    })
  }


  getDriverCashofParticularLR(id: string) {
    this.spinner.show();
    this.superAdminService.getDriverCashofParticularLR(id).subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        this.driverCashData = res.data
        // console.log("driver Cash Data", this.driverCashData)
        this.sumOfDriverCash = this.driverCashData.map((x) => x.amount_paid)
        // console.log("sum of driver cash",this.sumOfDriverCash)
        this.driverSum = this.sumOfDriverCash.reduce((accumulator, currentValue) => {
          return accumulator + currentValue + this.particlarLR.driver_cash
        }, 0);
        this.dataSource2 = new MatTableDataSource(this.driverCashData)
        this.spinner.hide()
      } else {
        if (res.status == 401) {
          this.toastr.error(res.message)
        } else {
          this.toastr.error(res.message)
        }
      }
    })
  }
  initform() {
    this.editLR.patchValue({
      // Basic Details

      clientList: this.particlarLR.clientName,
      LR_no: this.particlarLR.LR_no,
      //date: this.particlarLR.date,
      ConsigneeName: this.particlarLR.consignee_name,
      ConsigneeAddress: this.particlarLR.consignee_address,
      ConsignorName: this.particlarLR.consignor_name,
      ConsignorAddress: this.particlarLR.consignor_address,
      VehicleNo: this.particlarLR.vehicle_id,
      vehicleType: this.particlarLR.vehicleType,
      consignor_GSTIN: this.particlarLR.consignor_GSTIN,
      //VehicleNo: this.particlarLR.vehicle_no,
      from: this.particlarLR.from,
      to: this.particlarLR.to,
      no_Of_Packages: this.particlarLR.no_of_package,
      description: this.particlarLR.description,
      GSTIN: this.particlarLR.GSTIN,
      date: new Date(this.particlarLR.date).toISOString().split('T')[0],
      RoadPermitNo: this.particlarLR.road_permit_no,
      StateCode: this.particlarLR.stateCode,
      DLY: this.particlarLR.dly_no,
      DeclaredValue: this.particlarLR.declared_value,
      fixAdhoc: this.particlarLR.fixAdhoc,
      quantity: this.particlarLR.quantity,
      transportorName: this.particlarLR.transportorName,
      transportorMobile: this.particlarLR.transportorMobile,
      driverName: this.particlarLR.driverName,
      driverMobile: this.particlarLR.driverMobile,
      Frieght: this.particlarLR.Frieght,

      // Information For office use only

      startmeterReading: this.particlarLR.startmeterReading,
      endmeterReading: this.particlarLR.endmeterReading,
      tripSDate: new Date(this.particlarLR.tripStartDate).toISOString().split('T')[0],
      tripEDate: new Date(this.particlarLR.tripEndDate).toISOString().split('T')[0],
      INtime: this.particlarLR.in_time,
      outtime: this.particlarLR.out_time,
      noofTools: this.particlarLR.noofTools,
      tooltaxPaid: this.particlarLR.tooltaxPaid,
      labourPaid: this.particlarLR.labourPaid,
      otherPayment: this.particlarLR.otherPayment,
      updownkm: this.particlarLR.up_down_km,
      totalhour: this.particlarLR.totalhour,
      extrahour: this.particlarLR.extrahour,
      detention: this.particlarLR.detention,
      advance: this.particlarLR.advance,
      balance: this.particlarLR.balance,
      balancePaid: this.particlarLR.balancePaid,
      vehicleRate: this.particlarLR.vehicleRate,


    })
  }
  updateLRSubmit() {
    if (this.editLR.valid) {
      // Basic Details 

      this.removevalidatorcss("LR_noctrl");
      this.removevalidatorcss("ConsigneeNamectrl");
      this.removevalidatorcss("ConsigneeAddressctrl");
      this.removevalidatorcss("ConsignorAddressctrl");
      this.removevalidatorcss("ConsignorNamectrl");
      this.removevalidatorcss("VehicleNoctrl");
      this.removevalidatorcss("fromctrl");
      this.removevalidatorcss("toctrl");
      this.removevalidatorcss("no_Of_Packagesctrl");
      this.removevalidatorcss("descriptionctrl");
      this.removevalidatorcss("GSTINctrl");
      this.removevalidatorcss("datectrl");
      // this.removevalidatorcss("statusctrl");
      // Information For office use only
      this.removevalidatorcss("startmeterReadingctrl");
      this.removevalidatorcss("endmeterReadingctrl");
      this.removevalidatorcss("outtimectrl");
      this.removevalidatorcss("INtimectrl");
      this.removevalidatorcss("noofToolsctrl");
      this.removevalidatorcss("tooltaxPaidctrl");
      this.removevalidatorcss("labourPaidctrl");
      this.removevalidatorcss("otherPaymentctrl");
      this.removevalidatorcss("updownkmctrl");
      this.removevalidatorcss("totalhourctrl");
      this.removevalidatorcss("vehicleRatectrl");


      //  var c = this.addNewLR.get('InvoiceNo').value
      //   + ',' + document.getElementById("new1")['value']
      //   + ',' + document.getElementById("new2")['value']
      //   + ',' + document.getElementById("new3")['value'];
      var form = {
        // Add Invoice Details
        "_id": this.particlarLR._id,

        // Basic Details

        "ConsigneeName": this.editLR.get('ConsigneeName').value,
        "ConsigneeAddress": this.editLR.get('ConsigneeAddress').value,
        "ConsignorName": this.editLR.get('ConsignorName').value,
        "ConsignorAddress": this.editLR.get('ConsignorAddress').value,
        "VehicleNo": this.editLR.get('VehicleNo').value,
        "GSTIN": this.editLR.get('GSTIN').value,
        "consignor_GSTIN": this.editLR.get('consignor_GSTIN').value,
        "from": this.editLR.get('from').value,
        "to": this.editLR.get('to').value,
        "no_Of_Packages": this.editLR.get('no_Of_Packages').value,
        "description": this.editLR.get('description').value,
        "date": this.editLR.get('date').value,
        //"status": this.editLR.get('status').value,

        // Information For office use only

        "startmeterReading": this.editLR.get('startmeterReading').value,
        "endmeterReading": this.editLR.get('endmeterReading').value,
        "INtime": this.editLR.get('INtime').value,
        "outtime": this.editLR.get('outtime').value,
        "noofTools": this.editLR.get('noofTools').value,
        "tooltaxPaid": this.editLR.get('tooltaxPaid').value,
        "labourPaid": this.editLR.get('labourPaid').value,
        "otherPayment": this.editLR.get('otherPayment').value,
        "updownkm": this.editLR.get('updownkm').value,
        "totalhour": this.editLR.get('totalhour').value,
        "vehicleRate": this.editLR.get('vehicleRate').value,

      }
      this.superAdminService.updateLR({ form })
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

          if (res.status == true) {
            //alert(res.message)
            this.toastr.success(res.message);
            this.router.navigate(['/superAdmin/lrmanagement']);
            //this.editLR.reset()
          }
          else {
            // alert(res.message)
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');

            } else {
              this.toastr.success(res.message);
            }
            //this.editLR.reset()
          }

        }, error => {
        });
    }
    else {
      // Basic Details 

      if (this.editLR.get('ConsigneeAddress').invalid) {
        this.addvalidatorcss("ConsigneeAddressctrl");
      } else {
        this.removevalidatorcss("ConsigneeAddressctrl");
      }
      if (this.editLR.get('ConsignorName').invalid) {
        this.addvalidatorcss("ConsignorNamectrl");
      } else {
        this.removevalidatorcss("ConsignorNamectrl");
      }
      if (this.editLR.get('ConsigneeName').invalid) {
        this.addvalidatorcss("ConsigneeNamectrl");
      } else {
        this.removevalidatorcss("ConsigneeNamectrl");
      }
      if (this.editLR.get('ConsignorAddress').invalid) {
        this.addvalidatorcss("ConsignorAddressctrl");
      } else {
        this.removevalidatorcss("ConsignorAddressctrl");
      }
      if (this.editLR.get('VehicleNo').invalid) {
        this.addvalidatorcss("VehicleNoctrl");
      } else {
        this.removevalidatorcss("VehicleNoctrl");
      }
      if (this.editLR.get('from').invalid) {
        this.addvalidatorcss("fromctrl");
      } else {
        this.removevalidatorcss("fromctrl");
      }
      if (this.editLR.get('to').invalid) {
        this.addvalidatorcss("toctrl");
      } else {
        this.removevalidatorcss("toctrl");
      }
      if (this.editLR.get('no_Of_Packages').invalid) {
        this.addvalidatorcss("no_Of_Packagesctrl");
      } else {
        this.removevalidatorcss("no_Of_Packagesctrl");
      }
      if (this.editLR.get('description').invalid) {
        this.addvalidatorcss("descriptionctrl");
      } else {
        this.removevalidatorcss("descriptionctrl");
      }
      if (this.editLR.get('date').invalid) {
        this.addvalidatorcss("datectrl");
      } else {
        this.removevalidatorcss("datectrl");
      }
      if (this.editLR.get('GSTIN').invalid) {
        this.addvalidatorcss("GSTINctrl");
      } else {
        this.removevalidatorcss("GSTINctrl");
      }
      if (this.editLR.get('consignor_GSTIN').invalid) {
        this.addvalidatorcss("consignor_GSTINctrl");
      } else {
        this.removevalidatorcss("consignor_GSTINctrl");
      }

      // Information For office use only  

      if (this.editLR.get('startmeterReading').invalid) {
        this.addvalidatorcss("startmeterReadingctrl");
      } else {
        this.removevalidatorcss("startmeterReadingctrl");
      }
      if (this.editLR.get('endmeterReading').invalid) {
        this.addvalidatorcss("endmeterReadingctrl");
      } else {
        this.removevalidatorcss("endmeterReadingctrl");
      }
      if (this.editLR.get('outtime').invalid) {
        this.addvalidatorcss("outtimectrl");
      } else {
        this.removevalidatorcss("outtimectrl");
      }
      if (this.editLR.get('INtime').invalid) {
        this.addvalidatorcss("INtimectrl");
      } else {
        this.removevalidatorcss("INtimectrl");
      }
      if (this.editLR.get('noofTools').invalid) {
        this.addvalidatorcss("noofToolsctrl");
      } else {
        this.removevalidatorcss("noofToolsctrl");
      }
      if (this.editLR.get('tooltaxPaid').invalid) {
        this.addvalidatorcss("tooltaxPaidctrl");
      } else {
        this.removevalidatorcss("tooltaxPaidctrl");
      }
      if (this.editLR.get('labourPaid').invalid) {
        this.addvalidatorcss("labourPaidctrl");
      } else {
        this.removevalidatorcss("labourPaidctrl");
      }
      if (this.editLR.get('otherPayment').invalid) {
        this.addvalidatorcss("otherPaymentctrl");
      } else {
        this.removevalidatorcss("otherPaymentctrl");
      }
      if (this.editLR.get('updownkm').invalid) {
        this.addvalidatorcss("updownkmctrl");
      } else {
        this.removevalidatorcss("updownkmctrl");
      }
      if (this.editLR.get('totalhour').invalid) {
        this.addvalidatorcss("totalhourctrl");
      } else {
        this.removevalidatorcss("totalhourctrl");
      }
      if (this.editLR.get('vehicleRate').invalid) {
        this.addvalidatorcss("vehicleRatectrl");
      } else {
        this.removevalidatorcss("vehicleRatectrl");
      }

    }
  }

  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove('validatorcss');
  }

  // editInvoice(data){
  //   const dialogRef = this.dialog.open(EditInvoiceComponent,data);
  // }
  addnewinv(acc) {
    const dialogRef = this.dialog.open(AddInvoiceComponent, {
      panelClass: 'addInvoice',
      data: {
        acc,
        getLrDetail: () => { this.getLRDetail(this.paramId) }
      }
    })
  }
  editInvoice(acc, id): void {
    const dialogRef = this.dialog.open(EditInvoiceComponent, {
      panelClass: 'addInvoice',
      data: {
        acc,
        id,
        getLrDetail: () => { this.getLRDetail(this.paramId) }
      }
    });
  }
  previewLoadingSlip(imageUrl: any, backImageUrl: any) {
    const url = imageUrl;
    const backUrl = backImageUrl;
    if (url) {
      let fileExtension = url.split('.').slice(-1)[0];
      if (['jpeg', 'png', 'jpg', 'svg'].includes(fileExtension)) {
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width: '80%',
          height: '90vh',
          data: { file: url, backFile: backUrl }
        });
      }
      else {
        // this.previewLoadingSlipURL = this.sanitizer.bypassSecurityTrustUrl(this.apiUrl+'/'+url);
        // setTimeout(()=>{
        //   this.showLoadingSlipPreview.nativeElement.click();
        // })
      }
    }
    else {
      this.toastr.error("No attachment")
    }
  }
  expenseTab(tabname: any) {
    this.isTab = tabname
  }
  sortData(sort: Sort) {

  }

  addExpence() {
    // console.log("workin", this.paramId)
    const dialogRef = this.dialog.open(AddfuelExpenseComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { add: this.paramId, heading: "Add" }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('Dialog result', result);
      if (result === true) {
        this.getfuelExpenseofParticularLR(this.paramId)

      }
    });

  }

  editExpence(id: any) {
    // console.log("workin", this.paramId)
    // console.log("workin2", id)
    const dialogRef = this.dialog.open(AddfuelExpenseComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { edit: id, heading: "Edit" }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('Dialog result', result);
      if (result === true) {
        this.getfuelExpenseofParticularLR(this.paramId)

      }
    });
  }
  driverExpence() {
    const dialogRef = this.dialog.open(AddDriverExpenseComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { add: this.paramId, heading: "Add" }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('Dialog result', result);
      if (result === true) {
        this.getDriverCashofParticularLR(this.paramId)

      }
    });
  }
  editdriverExpence(ids: any) {
    const dialogRef = this.dialog.open(AddDriverExpenseComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { edit: ids, heading: "Edit" }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('Dialog result', result);
      if (result === true) {
        this.getDriverCashofParticularLR(this.paramId)

      }
    });
  }

  edittruckexpense(ids: any) {
    const dialogRef = this.dialog.open(TruckExpenceComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { edit: ids, heading: "Edit", lrID: this.paramId }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.getParticulartruckExpense(ids);
      }
    });
    console.log("this.paramId11", this.paramId);
    console.log("data", ids);
  }

  editotherExpence(ids: any) {
    const dialogRef = this.dialog.open(OtherexpenceComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { edit: ids, heading: "Edit", lrID: this.paramId }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.getParticularotherExpense(ids);
      }
    });
    console.log("this.paramId11", this.paramId);
    console.log("data", ids);
  }

  editadvanceexpense(ids: any) {
    const dialogRef = this.dialog.open(AdvanceComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { edit: ids, heading: "Edit", lrID: this.paramId }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.getParticularadvanceExpense(ids);
      }
    });
    console.log("this.paramId11", this.paramId);
    console.log("data", ids);
  }
  backTo() {
    window.history.back()
  }


  get_all_truck_expense() {
    this.spinner.show();
    this.superAdminService.get_all_truck_expense().subscribe((res: any) => {
      if (res.status == true) {
        this.spinner.hide();

        let all_truck_expense = res.data;
        this.all_truck_expensess = all_truck_expense
        // console.log("this.all_truck_expensess",this.all_truck_expensess);
        // debugger;
        for (let i = 0; i < this.all_truck_expensess.length; i++) {
          let resultt = this.all_truck_expensess[i].lr_ID
          if (this.paramId === resultt) {
            this.final_filter_truck.push(this.all_truck_expensess[i])
            // this.final_filter_advance = this.all_advance_expensess;

          }

        }
        console.log("this.final_filter_truck", this.final_filter_truck);

        this.truck_expense = new MatTableDataSource(this.final_filter_truck);
        this.truck_expense.paginator = this.paginator;

      }
    })
  }

  // get_all_advance_expense() {
  //   this.spinner.show();
  //   this.superAdminService.get_all_advance_expense().subscribe((res: any) => {
  //     if (res.status == true) {
  //       this.spinner.hide();

  //       let all_advance_expense = res.data;
  //       this.all_advance_expensess = all_advance_expense
  //       // debugger;
  //       for (let i = 0; i < this.all_advance_expensess.length; i++) {
  //         let resultt = this.all_advance_expensess[i].lr_ID
  //         if (this.paramId === resultt) {
  //           this.final_filter_advance.push(this.all_advance_expensess[i])
  //           // this.final_filter_advance = this.all_advance_expensess;

  //         }

  //       }

  //       this.advance_expenses = new MatTableDataSource(this.final_filter_advance)
  //       this.advance_expenses.paginator = this.paginator;

  //     }
  //   })
  // }


  // get_all_other_expense() {
  //   this.spinner.show();
  //   this.superAdminService.get_all_other_expense().subscribe((res: any) => {
  //     if (res.status == true) {
  //       this.spinner.hide();

  //       let all_other_expense = res.data;
  //       this.all_other_expensess = all_other_expense
  //       // console.log("this.all_truck_expensess",this.all_truck_expensess);
  //       // debugger;
  //       for (let i = 0; i < this.all_other_expensess.length; i++) {
  //         let resultt = this.all_other_expensess[i].lr_ID
  //         if (this.paramId === resultt) {
  //           this.final_filter_other.push(this.all_other_expensess[i])
  //           // this.final_filter_advance = this.all_advance_expensess;

  //         }

  //       }
  //       console.log("this.final_filter_other", this.final_filter_other);

  //       this.other_expense = new MatTableDataSource(this.final_filter_other);
  //       this.other_expense.paginator = this.paginator;

  //     }
  //   })
  // }

  checkin(fromDate: any, todate: any) {
    switch (this.isTab) {
      case 'fuelExpense':
        var result = [];
        this.fuelExpenseData.forEach(function (data) {
          // console.log("workin", data.date_of_expenses)

          if (data.date_of_expenses >= fromDate && data.date_of_expenses <= todate) {
            result.push(data)

          }
        })
        // console.log("Datass",result)
        this.fuelExpenseDataSource = new MatTableDataSource(result)

        return result
        break;
      case 'driverExpense':
        this.driverCashData

        var result = [];
        this.driverCashData.forEach(function (data) {
          // console.log("workin", data.date_of_payment)

          if (data.date_of_payment >= fromDate && data.date_of_payment <= todate) {
            result.push(data)

          }
        })
        // console.log("Datass",result)
        this.dataSource2 = new MatTableDataSource(result)

        return result

        break;

      case 'advance_expense':
        var result = [];
        this.final_filter_advance.forEach(function (data) {

          if (data.Avance_date_of_expenses >= fromDate && data.Avance_date_of_expenses <= todate) {
            result.push(data)
          }
        })

        this.advance_expenses = new MatTableDataSource(result)
        return result
        break;

      case 'truckExpense':
        var result = [];
        this.final_filter_truck.forEach(function (data) {

          if (data.date_of_expenses >= fromDate && data.date_of_expenses <= todate) {
            result.push(data)
          }
        })

        this.truck_expense = new MatTableDataSource(result)
        return result
        break;

      case 'otherExpense':
        var result = [];
        this.final_filter_other.forEach(function (data) {

          if (data.dateOfExpenses >= fromDate && data.dateOfExpenses <= todate) {
            result.push(data)
          }
        })

        this.other_expense = new MatTableDataSource(result)
        return result
        break;


      default:
    }



  }

  clear(fromDate: any, todate: any) {
    switch (this.isTab) {
      case 'fuelExpense':
        fromDate.value = '';
        todate.value = '';
        this.getfuelExpenseofParticularLR(this.paramId);

        break;
      case 'driverExpense':
        fromDate.value = '';
        todate.value = '';
        this.getDriverCashofParticularLR(this.paramId);

        break;

      case 'advance_expense':
        fromDate.value = '';
        todate.value = '';
        this.getadvanceExpenseofParticularLR(this.paramId)
        window.location.reload();
        break;

      case 'truckExpense':
        fromDate.value = '';
        todate.value = '';
        this.gettruckExpenseofParticularLR(this.paramId)
        window.location.reload();
        break;

      case 'otherExpense':
        fromDate.value = '';
        todate.value = '';
        this.getotherExpenseofParticularLR(this.paramId);
        window.location.reload();

        break;

    }
  };

  getotherExpenseofParticularLR(id: any) {
    this.spinner.show();
    this.superAdminService.getotherExpenseofParticularLR(id).subscribe((res: any) => {
      if (res.status = true) {
        this.spinner.hide();
        let all_other_expense = res.data;
        this.all_other_expensess = all_other_expense
        console.log("erawdatatatatatataa", this.all_other_expensess);
        for (let i = 0; i < this.all_other_expensess.length; i++) {
          let resultt = this.all_other_expensess[i].lr_ID
          if (this.paramId === resultt) {
            this.final_filter_other.push(this.all_other_expensess[i])
            // this.final_filter_advance = this.all_advance_expensess;

          }

        }

        this.other_expense = new MatTableDataSource(this.final_filter_other);
        this.other_expense.paginator = this.paginator;
      }
    })
  }


  deletetruckexpense(_id, lrid) {
    if (this.superAdminService.deleteDailogBox()) {
      this.superAdminService.deletetruckExpense(_id, lrid)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {
          if (res.status == true) {
            this.toastr.success(res.message);
            this.getParticulartruckExpense(this.paramId);
            window.location.reload();
          } else {
            if (res.status == 401) {
              this.toastr.error(res.message)
            }
          }
        })
    }
  };

  delete_other_payment(_id, lrid) {
    if (this.superAdminService.deleteDailogBox()) {
      this.superAdminService.deleteotherExpense(_id, lrid)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {
          if (res.status == true) {
            this.toastr.success(res.message);
            this.getParticularotherExpense(this.paramId);
            window.location.reload();
          } else {
            if (res.status == 401) {
              this.toastr.error(res.message)
            }
          }
        })
    }
  };


  deleteadvanceExpense(_id, lrid) {
    if (this.superAdminService.deleteDailogBox()) {
      this.superAdminService.deleteadvanceExpense(_id, lrid)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {
          if (res.status == true) {
            this.toastr.success(res.message);
            this.getadvanceExpenseofParticularLR(this.paramId);
            window.location.reload();
          } else {
            if (res.status == 401) {
              this.toastr.error(res.message)
            }
          }
        })
    }
  };
  getadvanceExpenseofParticularLR(id: any) {
    this.spinner.show();
    this.superAdminService.getadvanceExpenseofParticularLR(id).subscribe((res => {
      if (res.status === true) {
        let dataa = res.data;
        this.getadvanceExpenseofParticularLR_data = dataa

        let all_advance_expense = this.getadvanceExpenseofParticularLR_data;

        this.all_advance_expensess = all_advance_expense
        console.log("!@##@!!@##@!", this.paramId);
        console.log("all_advance_expensess", this.all_advance_expensess);
        for (let i = 0; i < this.all_advance_expensess.length; i++) {
          let resultt = this.all_advance_expensess[i].lr_ID;
          if (this.paramId === resultt) {
            this.final_filter_advance.push(this.all_advance_expensess[i]);

            console.log("final_filter_advance", this.final_filter_advance)
            // this.final_filter_advance = this.all_advance_expensess;

          }

        }

        this.advance_expenses = new MatTableDataSource(this.final_filter_advance);

        this.advance_expenses.paginator = this.paginator;


        this.sumofadvanceexpense = this.getadvanceExpenseofParticularLR_data.map((x) => x.advance_amount);
        //  debugger;

        this.advance_sum = this.sumofadvanceexpense.reduce((accumulator, currentValue) => {
          return accumulator + currentValue + this.particlarLR?.advance
        }, 0);
        this.spinner.hide()
      }
    }))

  }

  gettruckExpenseofParticularLR(id: any) {
    this.spinner.show();
    this.superAdminService.gettruckExpenseofParticularLR(id).subscribe((res => {
      if (res.status === true) {
        let dataa = res.data;
        this.gettruckExpenseofParticularLR_data = dataa

        for (let i = 0; i < this.gettruckExpenseofParticularLR_data.length; i++) {
          let resultt = this.all_truck_expensess[i].lr_ID
          if (this.paramId === resultt) {
            this.final_filter_truck.push(this.all_truck_expensess[i])
            // this.final_filter_advance = this.all_advance_expensess;

          }

        }

        this.truck_expense = new MatTableDataSource(this.final_filter_truck);
        this.truck_expense.paginator = this.paginator;


        // this.sumoftruckexpense = this.gettruckExpenseofParticularLR_data.map((x) => x.ExpenseAmount);
        //  debugger;

        // this.truck_sum = this.sumoftruckexpense.reduce((accumulator, currentValue) => {
        //   return accumulator + currentValue + this.particlarLR?.advance
        // }, 0);
        this.spinner.hide()
      }
    }))

  }



  deleteDriverCash(_id) {
    if (this.superAdminService.deleteDailogBox()) {
      // console.log(_id)
      this.superAdminService.deleteDriverCash(_id)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {
          if (res.status == true) {
            this.toastr.success(res.message)
            this.getDriverCashofParticularLR(this.paramId)
          } else {
            if (res.status == 401) {
              this.toastr.error(res.message)
            }
          }
        })
    }
  }


  deleteFuelExpense(_id) {
    if (this.superAdminService.deleteDailogBox()) {

      this.superAdminService.deleteFuelExpense(_id)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {
          if (res.status == true) {
            this.toastr.success(res.message)
            this.getfuelExpenseofParticularLR(this.paramId)
          } else {
            if (res.status == 401) {
              this.toastr.error(res.message)
            }
          }
        })
    }
  }

  sanitizeImageUrl(imageUrl: any): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
  advanceexp() {
    const dialogRef = this.dialog.open(AdvanceComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { add: this.paramId, heading: "Add" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.getadvanceExpenseofParticularLR(this.paramId)

      }
    });
  };
  truckExpence() {
    const dialogRef = this.dialog.open(TruckExpenceComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { add: this.paramId, heading: "Add" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.getParticulartruckExpense(this.paramId)

      }
    });
  }

  addotherexpence() {
    const dialogRef = this.dialog.open(OtherexpenceComponent, {
      panelClass: ["addexpenceLoading", 'addexpensefuel'],
      data: { add: this.paramId, heading: "Add" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.getadvanceExpenseofParticularLR(this.paramId)

      }
    });
  };

  getParticulartruckExpense(id: any) {
    this.superAdminService.getParticularadvanceExpense(id).subscribe((res: any) => {
      if (res.status == true) {
        this.ParticularadvanceExpense = res.data[0]
      } else {
        if (res.status == 401) {
          this.toaster.error(res.message)
        } else {
          this.toaster.error(res.message)
        }
      }
    })
  }

  getParticularotherExpense(id: any) {
    this.superAdminService.getParticularotherExpense(id).subscribe((res: any) => {
      if (res.status == true) {
        this.ParticularotherExpense = res.data[0]
      } else {
        if (res.status == 401) {
          this.toaster.error(res.message)
        } else {
          this.toaster.error(res.message)
        }
      }
    })
  }


  getParticularadvanceExpense(id: any) {
    this.superAdminService.getParticularadvanceExpense(id).subscribe((res: any) => {
      if (res.status == true) {
        this.ParticularadvanceExpense = res.data[0]
      } else {
        if (res.status == 401) {
          this.toaster.error(res.message)
        } else {
          this.toaster.error(res.message)
        }
      }
    })
  }


  clearDate() {
    console.log("wwwooorrrkkkiiinnnggg")
    this.selectedDate = null;
  }


}
