import { Component } from '@angular/core';

@Component({
  selector: 'app-view-reports-layout',
  templateUrl: './view-reports-layout.component.html',
  styleUrls: ['./view-reports-layout.component.css']
})
export class ViewReportsLayoutComponent {

}
