<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Date Wise LR Expense Report : {{this.month}} {{year}}</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left"
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
        </div>
    </div>
    
    <div class="contentbox mt-3">
        <div class="row customepadding">
            <div class="col-md-12 table-responsive ">
                <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist "
                    *ngIf='LrExpenseArray.length > 0'>
                    <thead>
                        <tr>
                            <td>Manual LR number</td>
                            <td>Date</td>
                            <td>Company Name</td>
                            <td>Supervisor Name</td>
                            <td>LR Number</td>
                            <td>Vehicle Number </td>


                            <td>From location</td>
                            <td>To location</td>

                            <td>Starting date</td>

                            <td>End Date</td>
                            <td>Total value</td>

                            <td>Vehicle rate</td>

                            <td>Advance</td>

                            <td>Commission</td>

                            <td>Toll Tax Paid</td>
                            <td>Labour Paid</td>



                            <td>Diesel Expense</td>
                            <td>Driver Cash</td>
                            <td>Truck Expense</td>
                            <td>Other Payment</td>
                            <td>Detention</td>
                            <td>TDS</td>


                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user1 of LrExpenseArray;let i = index">
                            <td>{{user1.manualLR_no}}</td>
                            <td>{{user1.date | date:"dd/MM/yyyy"}}</td>
                            <td>{{user1.companyName}}</td>
                            <td>{{user1.SupervisorName}}</td>
                            <td>{{user1.LRNumber}}</td>
                            <td>{{user1.vehicleNumber}}</td>


                            <td>{{user1.from}}</td>
                            <td>{{user1.to}}</td>

                            <td>{{user1.tripStartDate | date:"dd/MM/yyyy"}}</td>

                            <td>{{user1.tripEndDate | date:"dd/MM/yyyy"}}</td>
                            <td>{{user1.unloadingchargercvd + user1.waitingCharge + user1.vehicleRate }}</td>

                            <td>{{vehiclerate[i][user1.LRNumber]}}</td>

                            
                            <td>{{user1.advance}}</td>

                            <td>{{user1.commission}}</td>

                            <td>{{user1.tooltaxPaid}}</td>
                            <td>{{user1.labourPaid}}</td>




                            <td>{{user1.dieselExpense}}</td>
                            <td>{{user1.driverCash}}</td>
                            <td>{{user1.truckExpense}}</td>
                            <td>{{user1.otherPayment}}</td>
                            <td>{{user1.detention}}</td>
                            <td>{{user1.tds}}</td>



                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>