import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-date-wise-lr-expense',
  templateUrl: './date-wise-lr-expense.component.html',
  styleUrls: ['./date-wise-lr-expense.component.css']
})
export class DateWiseLrExpenseComponent implements OnInit {
  expenseArr: any = [];
  LrExpenseArray: any = [];
  vehiclerate: any = [];
  myArr: any = [];
  paramId: any;
  users: any;
  month: any;
  userSessionData: any = JSON.parse(sessionStorage.getItem('userSession'));
  monthArrFull: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  monthArr: any = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  paramCompany: any;
  paramSupervisor: any;
  year: any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService, private superadminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.paramCompany = param.company;
      this.paramSupervisor = param.supervisor;
      this.month = this.monthArrFull[this.monthArr.indexOf(this.paramId)];
      this.year = param.year;
    })
  }

  dtOptions: any = {};
  ngOnInit(): void {
    this.getExpense();

    this.dtOptions = {
      dom: 'Bfrtip',

      buttons: [
        {
          extend: 'csv',
          text: 'Export'
        }

      ]
    }
  }
  getExpense() {
    this.superadminService.getlrs()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.myArr = res.data;
          // console.log(this.myArr);
          this.myArr = this.myArr.map((item) => item.receipt);
          console.log("this.Arr", this.myArr);
          this.myArr = this.myArr.filter((item) => {
            return item.date.split('T')[0].split('-')[0] == this.year
          })

          if (this.userSessionData.user_role_id[0] != 1) {
            this.myArr = this.myArr.filter((item) => {
              return item.supervisorId == this.userSessionData._id
            })
          }
          // console.log(this.myArr);
          this.superadminService.getClientList()
            .subscribe((client: { status: any, message: any, data: any }) => {
              if (client.status == true) {
                for (let i = 0; i < this.myArr.length; i++) {
                  if (this.monthArr[this.myArr[i].date.split('-')[1] - 1] == this.paramId && this.myArr[i].clientId == this.paramCompany && this.myArr[i].supervisorId == this.paramSupervisor) {
                    this.expenseArr.push({ "supervisorId": this.myArr[i].supervisorId, "date": this.myArr[i].date, "LRNumber": this.myArr[i].LR_no, "vehicleNumber": this.myArr[i].vehicle_id, "manualLR_no": this.myArr[i].manualLR_no, "from": this.myArr[i].from, "to": this.myArr[i].to, "companyId": this.myArr[i].clientId, "vehicleRate": this.myArr[i].vehicleRate, "labourPaid": this.myArr[i].labourPaid, "tooltaxPaid": this.myArr[i].tooltaxPaid, "tripStartDate": this.myArr[i].tripStartDate, "tripEndDate": this.myArr[i].tripEndDate, "otherPayment": this.myArr[i].otherPayment, "detention": this.myArr[i].detention, "advance": this.myArr[i].advance, "dieselExpense": this.myArr[i].diesel_expense, "driverCash": this.myArr[i].driver_cash, "truckExpense": this.myArr[i].truck_expense, "commission": this.myArr[i].commission, "waitingCharge": this.myArr[i].waitingCharge ,"tds": this.myArr[i].tds ,"unloadingchargercvd": this.myArr[i].unloadingchargercvd   } ,)
                  }
                }
                this.expenseArr.forEach((x, index) => {
                  client.data.filter(y => {
                    if (y._id == x.companyId) this.expenseArr[index].companyName = y.clientName
                  })
                })
                this.superadminService.getSuperVisorName().subscribe((supervisors: { status: any, message: any, data: any }) => {
                  if (supervisors.status == true) {
                    var supervisorsData = supervisors.data;
                    this.expenseArr.forEach((item, i) => {
                      this.expenseArr[i].SupervisorName = supervisorsData.filter((item) => this.expenseArr[i].supervisorId == item._id);
                      this.expenseArr[i].SupervisorName = this.expenseArr[i].SupervisorName[0] ? this.expenseArr[i].SupervisorName[0]['full_name'] : this.expenseArr[i].SupervisorName[0]
                    })
                    this.LrExpenseArray = this.expenseArr;
                     console.log("LrExpenseArray", this.LrExpenseArray)

                    this.vehiclerate = this.LrExpenseArray.map((x) => {
                      let vehicleRate = x.vehicleRate
                      let waitingCharge = x.waitingCharge
                      let lr = x.LRNumber

                      let total = vehicleRate 

                      return { [lr]: total }
                    })
                    // console.log("total ", this.vehiclerate)
                  } else {
                    // console.log(res.message);
                  }
                }, error => {
                  // console.log('error', error);
                })
              }
              else {
                if (res.status == 401) {
                  this.toastr.error(res.message);
                  window.sessionStorage.clear();
                  this.router.navigateByUrl('superAuth/login');

                } else {
                  this.toastr.error(res.message);
                }
              }

            }, error => {
            });
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      }
      )
  }
  backTo() {
    window.history.back()
  }

}
