<div class="header">
    <h1>Add Truck Expense</h1>
    <button mat-dialog-close>
        <img src="../../../../assets/images/svg/closeBtn.svg" alt="" srcset="">
    </button>
</div>
<div class="scrollHeightDialog">
    <div class="row m-0 py-4">
        <div class="col-xl-11 mx-auto" [formGroup]="add_truck_exp" (ngSubmit)="truckExpenseSubmit()">
            <div class="row">
                <div class="col-xl-6 mb-3">
                    <div class="form-group">
                        <label for="">Date of Expenses<sup text="danger">*</sup></label>
                        <input type="date" class="form-control" formControlName="dateOfExpenses"
                            placeholder="Enter Date of Expenses" />
                    </div>
                    <span class="text-danger"
                        *ngIf="ff.dateOfExpenses.invalid && (ff.dateOfExpenses.touched || submitBtn)">
                        Please Enter Truck Expense Date
                    </span>
                </div>
                <div class="col-xl-6 mb-3">
                    <div class="form-group">
                        <label for="">Expense location<sup text="danger">*</sup></label>
                        <input type="text" class="form-control" formControlName="expenses_location"
                            placeholder="Enter Expense location" />
                    </div>
                    <span class="text-danger"
                        *ngIf="ff.expenses_location.invalid && (ff.expenses_location.touched || submitBtn)">
                        Please Enter Expense location
                    </span>
                </div>


                <div class="col-xl-6 mb-3">
                    <div class="form-group">
                        <label for="">Expense Amount<sup text="danger">*</sup></label>
                        <input type="number" class="form-control" formControlName="ExpenseAmount"
                            placeholder="Enter Expense Amount" />
                    </div>
                    <span class="text-danger"
                        *ngIf="ff.ExpenseAmount.invalid && (ff.ExpenseAmount.touched || submitBtn)">
                        Please Enter Expense Amount
                    </span>
                </div>
                <div class="col-xl-6 mb-3">
                    <div class="form-group">
                        <label for="">Payment Mode<sup text="danger">*</sup></label>
                        <select name="" class="form-control form-select " id="" formControlName="paymentMode">
                            <option disabled value="">--Select Payment Mode--</option>
                            <option value="upi">UPI</option>
                            <option value="cash">Cash</option>
                            <option value="petroCard">Petro Card</option>
                        </select>
                    </div>
                    <span class="text-danger" *ngIf="ff.paymentMode.invalid && (ff.paymentMode.touched || submitBtn)">
                        Please Select Payment Mode
                    </span>
                </div>
                <div class="col-xl-6 mb-3">
                    <div class="form-group">
                        <label for="">Expense Type<sup text="danger">*</sup></label>
                        <input type="text" class="form-control" formControlName="Expenses_type"
                            placeholder="Enter Expense Type" />
                    </div>
                    <span class="text-danger"
                        *ngIf="ff.Expenses_type.invalid && (ff.Expenses_type.touched || submitBtn)">
                        Please Enter Expense Type
                    </span>
                </div>


                <div class="col-xl-6 mb-3">
                    <div class="form-group">
                        <label for="">Description</label>
                        <textarea class="col-xl-6 mb-3" type="text" class="form-control" formControlName="description"
                            placeholder="Enter Description"></textarea>
                    </div>
                </div>



                <div class="col-xl-12 text-center">
                    <button class="btn btn-dark py-2 px-5" mat-raised-button
                        (click)="truckExpenseSubmit()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>