import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';
import { AddfuelExpenseComponent } from '../addfuel-expense/addfuel-expense.component';

@Component({
  selector: 'app-otherexpence',
  templateUrl: './otherexpence.component.html',
  styleUrls: ['./otherexpence.component.css']
})
export class OtherexpenceComponent {

  submitBtn: boolean = false;
  id: any;
  ParticularotherExpense: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public formBuilder: FormBuilder, public superAdminServices: SuperAdminService, public toaster: ToastrService, private toster: ToastrService, private router: Router, private activatedRoute: ActivatedRoute, public dialogRef: MatDialogRef<AddfuelExpenseComponent>, private sanitizer: DomSanitizer) {
    this.activatedRoute.params.subscribe((param) => {
      this.id = param.id;
      // this.getadvanceExpenseofParticularLR(this.paramId)
      console.log("paramaa", this.data);
      console.log("this.data.lrID",this.data.lrID);
      //console.log("paramaa",param);
      //console.log("this.dataatatatatata",this.data);
      console.log("data1122", this.data.edit)
      if (this.data.edit != undefined) {
        this.getParticularotherExpense(this.data.edit);
      }
    })
  }


  add_other_exp = this.formBuilder.group({
    dateOfExpenses: ['', Validators.required],
    ExpenseAmount: ['', Validators.required],
    paymentMode: ['', Validators.required],
    description: [''],
    Expenses_type: ['', Validators.required],
    lr_id: this.data.add,

  })

get ff(){
  return this.add_other_exp.controls
}
  

  otherExpenseSubmit() {

    this.submitBtn = true;

    console.log("this.data.lrID",this.data.lrID);
    if (this.data.heading == 'Edit') {
      console.log("CCCCCheck", this.data.edit)
      let form = {
        ...this.add_other_exp.value,
        _id: this.data.edit,
        lr_id: this.data.lrID
      }
      let id = this.data.edit
      console.log("FORM", form, id);
     
      this.superAdminServices.update_other_Expense({ form, id }).subscribe((res: { status: true, message: any, authorization: any, code: any }) => {
        if (res.status == true) {
          this.toster.success(res.message);
          window.location.reload();
          this.dialogRef.close(res.status);
          console.log("checkkkkssksksksksksks");
        } else {
          if (res.status == 401) {
            this.toster.success(res.message);
          } else {
            this.toster.error(res.message);
          }
        }
      })
    } else {

      if (this.add_other_exp.valid) {
        this.submitBtn = true;
        let form = this.add_other_exp.value;
        // console.log("form", form);
        // const formData = new FormData();
        // const files: Array<File> = this.attach_Slips;

        // for (let i = 0; i < this.attach_Slips.length; i++) {
        //   formData.append("uploads[]", this.attach_Slips[i]);
        // }
        // formData.append('myFormData', JSON.stringify(form));


        this.superAdminServices.add_other_Expense({ form }).subscribe(
          (res: {
            status: any;
            message: any;
            authorization: any;
            code: any;
          }) => {
            // console.log("rrrccccrc", res);

            if (res.status == true) {
              this.toster.success(res.message);
              // this.add_advance_exp.reset();
              console.log("checkkkkssksksksksksks");
              window.location.reload();
              this.dialogRef.close(res.status);
            } else {
              if (res.status == 401) {
                this.toster.error(res.message);
                // window.sessionStorage.clear();
                // this.router.navigateByUrl("superAuth/login");
              } else {
                this.toster.error(res.message);
              }
            }
          },
          (error) => { }
        );
      } 
    }
  }

  getParticularotherExpense(id: any) {
    console.log("ididididdi", id);
    this.superAdminServices.getParticularotherExpense(id).subscribe((res: any) => {
      if (res.status == true) {
        this.ParticularotherExpense = res.data
        console.log("ParticulartruckExpense", this.ParticularotherExpense);
        this.initForm();

      } else {
        if (res.status == 401) {
          this.toaster.error(res.message)
        } else {
          this.toaster.error(res.message)
        }
      }
    })
  };

  initForm() {
    console.log("amount", this.ParticularotherExpense)
    this.add_other_exp.patchValue({

      ExpenseAmount: this.ParticularotherExpense[0].ExpenseAmount,
      dateOfExpenses: this.ParticularotherExpense[0].dateOfExpenses,
      description: this.ParticularotherExpense[0].description,
      paymentMode:this.ParticularotherExpense[0].paymentMode,
      Expenses_type: this.ParticularotherExpense[0].Expenses_type


    })
  };

  reloadPage() {
    window.location.reload()
  }

}
