import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-monthly-lr-expense',
  templateUrl: './monthly-lr-expense.component.html',
  styleUrls: ['./monthly-lr-expense.component.css']
})
export class MonthlyLrExpenseComponent implements OnInit {
  monthArr : any=["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  monthArrFull : any=["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  paramId: any;
  month: any;
  LrExpenseArray:any = [];
  userSessionData:any=JSON.parse(sessionStorage.getItem('userSession'));
  myArr: any;
  expenseArr: any = [];
  year: any;
  constructor( private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService, private superadminService: SuperAdminService) { 
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.month;
      this.month = this.monthArrFull[this.monthArr.indexOf(this.paramId)];
      this.year = param.year;
    })
  }
  dtOptions: any = {};

  ngOnInit(): void {
    this.getLrExpenseGroup();
    this.dtOptions = {
      dom: 'Bfrtip',
      
        buttons: [
          {
            extend: 'csv',
            text: 'Export'  
          }
          
        ]
      }
  }
  getLrExpenseGroup() {
    this.superadminService.getLRExpenseParticularMonth()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if(res.status == true ){
          this.superadminService.getClientList()
            .subscribe((client: { status: any, message: any, data: any }) => {
              if (client.status == true) {
                this.myArr= res.data;
                this.myArr = this.myArr.filter((item)=>{
                  return item._id.year == this.year
                })
                if(this.userSessionData.user_role_id[0]!=1){
                  this.myArr = this.myArr.filter((item)=>{
                    return item._id.supervisorId==this.userSessionData._id
                  })
                }
                this.myArr = this.myArr.filter((item)=>{
                  return this.monthArr[item._id.month-1] == this.paramId
                })
                for(let i=0; i<this.myArr.length; i++){
                  this.expenseArr.push({"supervisorId":this.myArr[i]._id.supervisorId,"companyId":this.myArr[i]._id.companyId, "amount":this.myArr[i].amount  })
                }
                this.expenseArr.forEach((x, index) => {
                  client.data.filter(y => {
                    if (y._id == x.companyId) this.expenseArr[index].companyName = y.clientName
                  })
                })
                this.superadminService.getSuperVisorName().subscribe((supervisors: {status: any, message: any, data: any}) => {
                  if(supervisors.status == true){
                    var supervisorsData = supervisors.data;
                    this.expenseArr.forEach((item,i)=>{
                      this.expenseArr[i].SupervisorName = supervisorsData.filter((item)=> this.expenseArr[i].supervisorId==item._id);
                      this.expenseArr[i].SupervisorName = this.expenseArr[i].SupervisorName[0]?this.expenseArr[i].SupervisorName[0]['full_name']:this.expenseArr[i].SupervisorName[0]
                    })
                    this.LrExpenseArray = this.expenseArr;
                  }else{
                    // console.log(res.message);
                  }
                }, error => {
                  // console.log('error', error);
                })
              }
              else {
                if (res.status == 401) {
                  this.toastr.error(res.message);
                  window.sessionStorage.clear();
                  this.router.navigateByUrl('superAuth/login');

                } else {
                  this.toastr.error(res.message);
                }
              }

            }, error => {
            });
        }else{
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        } 
      }
      )
  }
  backTo(){
    window.history.back()
  }

}
