<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View Vehicle</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" (click)="backTo()">Manage Vehicle </li>
                    <li class="breadcrumb-item active" aria-current="page">View Vehicle</li>

                </ol>
            </nav>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
                <div class="contentbox">
                    <div class="row account">
                        <div class="col-xl-12">
                            <div class="detailsBox">
                                <div class="row">
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Vehicle Number</label>
                                        <h5>{{particlarVehicle?.vehicle_no ? particlarVehicle?.vehicle_no:'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Vehicle Type</label>
                                        <h5>{{particlarVehicle?.type ? particlarVehicle?.type:'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Owner Name</label>
                                        <h5>{{particlarVehicle?.ownerName ? particlarVehicle?.ownerName :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Driver Name</label>
                                        <h5>{{particlarVehicle?.driverName ? particlarVehicle?.driverName :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Maker Name</label>
                                        <h5>{{particlarVehicle?.makerName ? particlarVehicle?.makerName :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Chassis Number</label>
                                        <h5>{{particlarVehicle?.chassisNumber ? particlarVehicle?.chassisNumber :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Engine Number</label>
                                        <h5>{{particlarVehicle?.engineNumber ? particlarVehicle?.engineNumber:'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Permit Number</label>
                                        <h5>{{particlarVehicle?.permitNumber ? particlarVehicle?.permitNumber:'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Policy Number</label>
                                        <h5>{{particlarVehicle?.policyNumber ? particlarVehicle?.policyNumber:'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">City</label>
                                        <h5>{{particlarVehicle?.city ? particlarVehicle?.city :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Permit Due Date</label>
                                        <h5>{{particlarVehicle?.permitDueDate | date:'yyyy-MM-dd'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Permit 5 Years Due Date</label>
                                        <h5>{{particlarVehicle?.permitYearsDueDate | date:'yyyy-MM-dd'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Purchase Date</label>
                                        <h5>{{particlarVehicle?.purchaseDate | date:'yyyy-MM-dd'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Road Tax Date</label>
                                        <h5>{{particlarVehicle?.roadTaxDate | date:'yyyy-MM-dd'  }}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Insurance Due Date</label>
                                        <h5>{{particlarVehicle?.insuranceDueDate | date:'yyyy-MM-dd'}}</h5>
                                    </div>
                                    <div class="col-xl-3">
                                        <label for="">Fitness Due Date</label>
                                        <h5>{{particlarVehicle?.fitnessDueDate | date:'yyyy-MM-dd'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Pollution Expiry Date</label>
                                        <h5>{{particlarVehicle?.pollutionExpiryDate| date:'yyyy-MM-dd'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Permit</label>
                                         <div class="viewImage">
                                            <img [id]="sanitizeImageUrl(apiUrl+'/uploads/'+particlarVehicle?.permit)" onClick="viewImages(this, 'Permit')" *ngIf="particlarVehicle?.permit" [src]="sanitizeImageUrl(apiUrl+'/uploads/'+particlarVehicle?.permit)"alt="Permit" />
                                        </div>
                                        <h5 *ngIf="!particlarVehicle?.permit">N/A</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">RC Copy</label>
                                        <div class="viewImage">
                                            <img [id]="sanitizeImageUrl(apiUrl+'/uploads/'+particlarVehicle?.RCCopy)" onClick="viewImages(this, 'RC Copy')" *ngIf="particlarVehicle?.RCCopy" [src]="sanitizeImageUrl(apiUrl+'/'+'/uploads/'+particlarVehicle?.RCCopy)" alt="RC Copy" />
                                        </div>
                                        <h5 *ngIf="!particlarVehicle?.RCCopy">N/A</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Insurance</label>
                                        <div class="viewImage">
                                            <img [id]="sanitizeImageUrl(apiUrl+'/uploads/'+particlarVehicle.insurance)" onClick="viewImages(this, 'Insurance')" *ngIf="particlarVehicle?.insurance" [src]="sanitizeImageUrl(apiUrl+'/'+'/uploads/'+particlarVehicle?.insurance)" alt="Insurance" />
                                        </div>
                                        <h5 *ngIf="!particlarVehicle?.insurance">N/A</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Permit 5 Years</label>
                                        <div class="viewImage">
                                            <img [id]="sanitizeImageUrl(apiUrl+'/uploads/'+particlarVehicle?.permitYears)" onClick="viewImages(this, 'Permit 5 Years')" *ngIf="particlarVehicle?.permitYears" [src]="sanitizeImageUrl(apiUrl+'/'+'/uploads/'+particlarVehicle?.permitYears)" alt="Permit 5 Years" />
                                        </div>
                                        <h5 *ngIf="!particlarVehicle?.permitYears">N/A</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Road Tax</label>
                                        <div class="viewImage">
                                            <img [id]="sanitizeImageUrl(apiUrl+'/uploads/'+particlarVehicle?.roadTax)" onClick="viewImages(this, 'Road Tax')" *ngIf="particlarVehicle?.roadTax" [src]="sanitizeImageUrl(apiUrl+'/'+'/uploads/'+particlarVehicle?.roadTax)" alt="Road Tax" />
                                        </div>
                                        <h5 *ngIf="!particlarVehicle?.roadTax">N/A</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Fitness</label>
                                        <div class="viewImage">
                                            <img [id]="sanitizeImageUrl(apiUrl+'/uploads/'+particlarVehicle?.fitness)" onClick="viewImages(this, 'Fitness')" *ngIf="particlarVehicle?.fitness" [src]="sanitizeImageUrl(apiUrl+'/'+'/uploads/'+particlarVehicle?.fitness)" alt="Fitness" />
                                        </div>
                                        <h5 *ngIf="!particlarVehicle?.fitness">N/A</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Owner Pancard</label>
                                        <div class="viewImage">
                                            <img [id]="sanitizeImageUrl(apiUrl+'/uploads/'+particlarVehicle?.ownerPancard)" onClick="viewImages(this, 'Owner Pancard')" *ngIf="particlarVehicle?.ownerPancard" [src]="sanitizeImageUrl(apiUrl+'/'+'/uploads/'+particlarVehicle?.ownerPancard)" alt="Owner Pancard" />
                                        </div>
                                        <h5 *ngIf="!particlarVehicle?.ownerPancard">N/A</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Owner Aadhar Card</label>
                                        <div class="viewImage">
                                            <img [id]="sanitizeImageUrl(apiUrl+'/uploads/'+particlarVehicle?.ownerAadharCard)" onClick="viewImages(this, 'Owner Aadhar Card')" *ngIf="particlarVehicle?.ownerAadharCard" [src]="sanitizeImageUrl(apiUrl+'/'+'/uploads/'+particlarVehicle?.ownerAadharCard)" alt="Owner Aadhar Card" />
                                        </div>
                                        <h5 *ngIf="!particlarVehicle?.ownerAadharCard">N/A</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Cancelled Cheque</label>
                                        <div class="viewImage">
                                            <img [id]="sanitizeImageUrl(apiUrl+'/uploads/'+particlarVehicle?.cancelledCheque)" onClick="viewImages(this, 'Cancelled Cheque')" *ngIf="particlarVehicle?.cancelledCheque" [src]="sanitizeImageUrl(apiUrl+'/'+'/uploads/'+particlarVehicle?.cancelledCheque)" alt="Cancelled Cheque" />
                                        </div>
                                        <h5 *ngIf="!particlarVehicle?.cancelledCheque">N/A</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
</div>