import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-view-advance-cash',
  templateUrl: './view-advance-cash.component.html',
  styleUrls: ['./view-advance-cash.component.css']
})
export class ViewAdvanceCashComponent {
  paramID: any;
  particular_advance: any;
  



  constructor(public activatedRoute: ActivatedRoute, public superAdminServices: SuperAdminService, public toaster: ToastrService, public dialog: MatDialog,){
    this.activatedRoute.params.subscribe(param => {
      this.paramID = param.id
      this.getParticularadvanceExpense(this.paramID)
      console.log("param",this.paramID);
      
    })

  }

  ngOnInit(): void {

   
  }

  getParticularadvanceExpense(id:any){
    this.superAdminServices.getParticularadvanceExpense(id).subscribe((res:any)=> {
      if(res.status == true){
        this.particular_advance = res .data[0]
        console.log("getParticularadvanceExpense",this.particular_advance);
      }
    })

  }

  backTo() {
  window.history.back()
}

// preview() {
//   let imagePath = this.particular_advance?.advance_slip;
//   if (imagePath) {
//     let fileExtension = imagePath.split('.').slice(-1)[0];
//     if (['jpeg', 'png', 'jpg', 'svg'].includes(fileExtension)) {
//       const dialogRef = this.dialog.open(ImagePreviewComponent, {
//         width: '80%',
//         height: '90vh',
//         data: { file: '/uploads/' + imagePath }
//       });
//     }
//     else {
//       this.slipURL = this.sanitizer.bypassSecurityTrustUrl(this.apiUrl + '/uploads/' + imagePath);
//       setTimeout(() => {
//         this.showSlip.nativeElement.click();
//       })
//     }
//   }
//   else {
//     this.toastr.error('No Slip Attached')
//   }
// }

}


