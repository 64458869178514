<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View User</h1>
                <button class="btn btn-dark btn-sm "  matTooltip="Back"
                matTooltipClass="example-tooltip-uppercase" matTooltipPosition="left" mat-raised-button (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                  <li class="breadcrumb-item" routerLink="/superAdmin/usermanagement">User Management</li>
                  <li class="breadcrumb-item active" aria-current="page">View User</li>
                </ol>
              </nav>
        </div>
    </div>
    <div class="contentbox  mt-3">
    <div class="row ">
        <div class="col-xl-12 mb-4">
            <div class="detailsBox">
                <div class="row">
                    <div class="col-xl-3">
                        <label for="">Name</label>
                        <h5>{{users.title+' '+users.firstname+' '+users.lastname}}</h5>
                    </div>
                    <div class="col-xl-3">
                        <label for="">Email</label>
                        <h5>{{users.email}}</h5>
                    </div>
                    <div class="col-xl-3">
                        <label for="">Mobile</label>
                        <h5>{{users.mobile}}</h5>
                    </div>
                    <div class="col-xl-3">
                        <label for="">Status</label>
                        <h5>{{users.status}}</h5>
                    </div>
                    <div class="col-xl-12">
                        <label for="">Assigned Companies</label>
                        <div class="clientsName">
                            <ul >
                                <li *ngFor="let client of users.clientList">{{client}}</li>
                            </ul>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>
        
    </div>
</div>