<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Monthly Vehicle Expense Report : {{month}} {{year}}</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left"
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
        </div>
    </div>
    <div class="contentbox mt-3">
        <div class="row customepadding">
            <div class="col-md-12 table-responsive ">
                <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist" *ngIf='vehicleExpenseArray.length > 0'>
                    <thead>
                        <tr>
                            <td>Vehicle Number</td>
                            <td>LR Count </td>
                            <td>Total Value</td>
                            <td>Vehicle Rate</td>
                            <td>Total Expense</td>
                            <td>Vehicle Expense</td>
                            <td>Income</td>
                            <td>Payment Received</td>
                            <td>Payment Due</td>
                            <td>Expense Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user1 of vehicleExpenseArray;let i = index">
                            <td
                                [routerLink]="['../../../dateWiseVehicle', paramId, user1.companyId, user1.supervisorId, this.year,user1.VehicleID]">
                                {{user1.VehicleID
                                }}</td>
                            <td
                                [routerLink]="['../../../dateWiseVehicle', paramId, user1.companyId, user1.supervisorId, this.year,user1.vehicle_no]">
                                {{this.vvcount[user1.vehicle_no]}}</td>
                            <td
                                [routerLink]="['../../../dateWiseVehicle', paramId, user1.companyId, user1.supervisorId, this.year,user1.vehicle_no]">
                                {{user1.total_value}}</td>
                            <td
                                [routerLink]="['../../../dateWiseVehicle', paramId, user1.companyId, user1.supervisorId, this.year,user1.vehicle_no]">
                                {{user1.vehicleRate}}</td>
                            <td
                                [routerLink]="['../../../dateWiseVehicle', paramId, user1.companyId, user1.supervisorId, this.year,user1.vehicle_no]">
                                {{user1.totalExpense}}</td>
                            <td
                                [routerLink]="['../../../dateWiseVehicle', paramId, user1.companyId, user1.supervisorId, this.year,user1.vehicle_no]">
                                {{user1.total_vehicle_expense}}</td>
                            <td
                                [routerLink]="['../../../dateWiseVehicle', paramId, user1.companyId, user1.supervisorId, this.year,user1.vehicle_no]">
                                {{user1.totalIncome}}</td>
                            <td
                                [routerLink]="['../../../dateWiseVehicle', paramId, user1.companyId, user1.supervisorId, this.year,user1.vehicle_no]">
                                {{user1.paymentReceived}}</td>
                            <td
                                [routerLink]="['../../../dateWiseVehicle', paramId, user1.companyId, user1.supervisorId, this.year,user1.vehicle_no]">
                                {{user1.payment_Due}}</td>
                            <td
                                [routerLink]="['../../../dateWiseVehicle', paramId, user1.companyId, user1.supervisorId, this.year,user1.vehicle_no]">
                                view</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>