import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/config';
import { SuperAdminService } from 'src/app/super-admin.service';
import { ImagePreviewComponent } from '../../LR-management/image-preview/image-preview.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-vehicle-maintenance',
  templateUrl: './vehicle-maintenance.component.html',
  styleUrls: ['./vehicle-maintenance.component.css']
})
export class VehicleMaintenanceComponent implements OnInit {
  filesToUpload: Array<File> = [];
  superVisor;
  company;
  vehicletypes;
  subCat;
  supervisorname = null
  expenseCategory = null
  companyname = null
  vehicleType = null;
  slipURL: any;
  Attachments: any;
  userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
  @ViewChild('showSlip') showSlip: ElementRef;
  addVicheleMaintenanceForm = new FormGroup({
    supervisorname: new FormControl(null, [Validators.required]),
    expenseCategory: new FormControl(null, [Validators.required]),
    companyname: new FormControl(null, [Validators.required]),
    vehicleType: new FormControl(null, [Validators.required]),
    vehicleNumber: new FormControl('', [Validators.required]),
    expenseDate: new FormControl('', [Validators.required]),
    //expenseHead: new FormControl('', [Validators.required]),
    otherCharges: new FormControl('', [Validators.required]),
    //labourAmount: new FormControl('', [Validators.required]),
    expenseAmount: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\d{1,}$/)])),
    expenseDescription: new FormControl('', [Validators.required]),
    //meterReading: new FormControl('', [Validators.required]),
    //workDescription: new FormControl('', [Validators.required]),
    //partsName: new FormControl('', [Validators.required]),
    //partAmount: new FormControl('', [Validators.required]),
    //labourCharge: new FormControl('', [Validators.required]),
    //workPlace: new FormControl('', [Validators.required]),
    mechanicName: new FormControl('', [Validators.required]),
    mechanicNumber: new FormControl('', [Validators.required]),
    LRReceipt1: new FormControl('', [Validators.required]),


  })
  submitLRClicked = false;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService, public dialog: MatDialog, private sanitizer: DomSanitizer) {
  }
  ngOnInit(): void {
    this.getCompanyName();
    this.getSuperVisorName();
    this.getVehicleType();
    this.getSubCat();
    if (this.userSessionData.user_role_id[0] != 1) {
      this.addVicheleMaintenanceForm.get("supervisorname").reset({ value: this.userSessionData._id, disabled: true });
    }
  }
  resetimg(image: any) {
    switch (image) {
      case 'attechment':
        this.Attachments = ''
        break;
      default:

    }
  }
  getSuperVisorName() {
    this.superAdminService.getSuperVisorName()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.superVisor = rawdata;
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });
  }
  getSubCat() {
    this.superAdminService.getSubCategory()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          // this.subCat = rawdata.expenseSubCategory;

          this.subCat = rawdata.filter(word => word.expenseCategory === 'Vehicle Maintenance');
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      }, error => {
      });
  }
  getCompanyName() {
    this.superAdminService.getClient()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.company = rawdata;
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });

  }
  getVehicleType() {
    this.superAdminService.getVehicleType()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.vehicletypes = rawdata;
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });

  }
  fileChangeEventattachReceipt1(fileInput: any) {
    this.filesToUpload = [];
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.filesToUpload.push(fileInput.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
    reader.onload = () => {
      const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.Attachments = reader.result;
    }
  }

  ff() {
    this.addVicheleMaintenanceForm.controls
  }

  addVicheleMaintenance() {
    this.submitLRClicked = true;
    if (this.addVicheleMaintenanceForm.valid) {
      this.removevalidatorcss("supervisornamectrl");
      this.removevalidatorcss("expenseCategoryctrl");
      this.removevalidatorcss("companynamectrl");
      this.removevalidatorcss("vehicleTypectrl");
      this.removevalidatorcss("vehicleNumberctrl");
      this.removevalidatorcss("expenseDatectrl");
      //this.removevalidatorcss("expenseHeadctrl");
      this.removevalidatorcss("otherChargesctrl");
      //this.removevalidatorcss("labourAmountctrl");
      this.removevalidatorcss("expenseAmountctrl");
      this.removevalidatorcss("expenseDescriptionctrl");
      this.removevalidatorcss("LRReceipt1ctrl");

      var form = {

        "supervisorId": this.addVicheleMaintenanceForm.get('supervisorname').value,
        "expenseSubCategoryId": this.addVicheleMaintenanceForm.get('expenseCategory').value,
        "companyId": this.addVicheleMaintenanceForm.get('companyname').value,
        "vehicleType": this.addVicheleMaintenanceForm.get('vehicleType').value,
        "vehicleNumber": this.addVicheleMaintenanceForm.get('vehicleNumber').value,
        "expenseDate": this.addVicheleMaintenanceForm.get('expenseDate').value,
        "otherCharges": this.addVicheleMaintenanceForm.get('otherCharges').value,
        "expenseAmount": this.addVicheleMaintenanceForm.get('expenseAmount').value,
        "expenseDescription": this.addVicheleMaintenanceForm.get('expenseDescription').value,
        "mechanicNumber": this.addVicheleMaintenanceForm.get('mechanicNumber').value,
        "mechanicName": this.addVicheleMaintenanceForm.get('mechanicName').value,

      }
      const formData = new FormData();
      const files: Array<File> = this.filesToUpload;

      for (let i = 0; i < this.filesToUpload.length; i++) {
        formData.append("uploads[]", this.filesToUpload[i]);
      }
      formData.append('myFormData', JSON.stringify(form));

      this.superAdminService.addVicheleMaintenance(formData)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

          if (res.status == true) {
            //alert(res.message)
            this.toastr.success(res.message);
            this.router.navigate(['../superAdmin/miscellaneous-expenses/vehicleMaintenence']);
            //this.router.navigate(['/superAdmin/manageDriver']);
            this.addVicheleMaintenanceForm.reset()
          }
          else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');

            } else {
              this.toastr.error(res.message);
            }
            //this.addNewUser.reset()
          }

        }, error => {
        });
    }
    else {
      if (this.addVicheleMaintenanceForm.get('supervisorname').invalid) {
        this.addvalidatorcss("supervisornamectrl");
      } else {
        this.removevalidatorcss("supervisornamectrl");
      }

      if (this.addVicheleMaintenanceForm.get('expenseCategory').invalid) {
        this.addvalidatorcss("expenseCategoryctrl");
      } else {
        this.removevalidatorcss("expenseCategoryctrl");
      }
      if (this.addVicheleMaintenanceForm.get('companyname').invalid) {
        this.addvalidatorcss("companynamectrl");
      } else {
        this.removevalidatorcss("companynamectrl");
      }
      if (this.addVicheleMaintenanceForm.get('vehicleType').invalid) {
        this.addvalidatorcss("vehicleTypectrl");
      } else {
        this.removevalidatorcss("vehicleTypectrl");
      }
      if (this.addVicheleMaintenanceForm.get('vehicleNumber').invalid) {
        this.addvalidatorcss("vehicleNumberctrl");
      } else {
        this.removevalidatorcss("vehicleNumberctrl");
      }
      if (this.addVicheleMaintenanceForm.get('expenseDate').invalid) {
        this.addvalidatorcss("expenseDatectrl");
      } else {
        this.removevalidatorcss("expenseDatectrl");
      }
      if (this.addVicheleMaintenanceForm.get('expenseDescription').invalid) {
        this.addvalidatorcss("expenseDescriptionctrl");
      } else {
        this.removevalidatorcss("expenseDescriptionctrl");
      }

      if (this.addVicheleMaintenanceForm.get('mechanicNumber').invalid) {
        this.addvalidatorcss("mechanicNumberctrl");
      } else {
        this.removevalidatorcss("mechanicNumberctrl");
      }
      if (this.addVicheleMaintenanceForm.get('mechanicName').invalid) {
        this.addvalidatorcss("mechanicNamectrl");
      } else {
        this.removevalidatorcss("mechanicNamectrl");
      }
      if (this.addVicheleMaintenanceForm.get('expenseAmount').invalid) {
        this.addvalidatorcss("expenseAmountctrl");
      } else {
        this.removevalidatorcss("expenseAmountctrl");
      }
      
      if (this.addVicheleMaintenanceForm.get('otherCharges').invalid) {
        this.addvalidatorcss("otherChargesctrl");
      } else {
        this.removevalidatorcss("otherChargesctrl");
      }
      if (this.addVicheleMaintenanceForm.get('LRReceipt1').invalid) {
        this.addvalidatorcss("LRReceipt1ctrl");
      } else {
        this.removevalidatorcss("LRReceipt1ctrl");
      }

    }
  }
  preview() {
    if (this.filesToUpload.length) {
      let file = this.filesToUpload[0];
      if (['image/jpeg', 'image/png', 'image/svg', 'image/svg+xml'].includes(file.type)) {
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width: '80%',
          height: '90vh',
          data: { file: file }
        });
      }
      else {
        let url = URL.createObjectURL(file);
        this.slipURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(() => {
          this.showSlip.nativeElement.click();
        })
      }
    }
    else {
      //condition if the stored image is a pdf,doc
      this.toastr.error('Please upload slip');
    }
  }
  resetForm() {
    this.addVicheleMaintenanceForm.reset();
    this.supervisorname = undefined;
    this.filesToUpload = [];
    if (this.userSessionData.user_role_id[0] != 1) {
      this.addVicheleMaintenanceForm.get("supervisorname").reset({ value: this.userSessionData.full_name, disabled: true });
    }
    // var a = this.addVicheleMaintenanceForm.get('supervisorname').value;
  }
  backTo() {
    window.history.back()
  }
  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove('validatorcss');
  }



}
