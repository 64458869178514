import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-view-truck-expense',
  templateUrl: './view-truck-expense.component.html',
  styleUrls: ['./view-truck-expense.component.css']
})
export class ViewTruckExpenseComponent {

  paramID: any;
  particular_truck: any;

  constructor(public activatedRoute: ActivatedRoute, public superAdminServices: SuperAdminService, public toaster: ToastrService, public dialog: MatDialog,){
    this.activatedRoute.params.subscribe(param => {
      this.paramID = param.id
      this.getParticulartruckExpense(this.paramID)
      console.log("param",this.paramID);
      
    })

  }

  getParticulartruckExpense(id:any){
    this.superAdminServices.getParticulartruckExpense(id).subscribe((res:any)=> {
      if(res.status == true){
        this.particular_truck = res .data[0]
        console.log("getParticulartruckExpense",this.particular_truck);
      }
    })

  }

  backTo() {
    window.history.back()
  }

}
