import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SuperAdminService } from '../../../super-admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gst-invoice',
  templateUrl: './gst-invoice.component.html',
  styleUrls: ['./gst-invoice.component.css']
})
export class GstInvoiceComponent implements OnInit {
  invtot: any;
  users: any;
  fivePercent: any;
  eightPercent: any;
  totalInvValueWithGST: any;
  paramId;
  waiting_charges: any;
  unloadingchargercvd: any;
  total_gst_value: any;
  sys_gen_no: any;
  constructor(private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private superAdminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getParticularInvoice(this.paramId);
      console.log("param",param);
    })
  }

  ngOnInit(): void {
  }

  getParticularInvoice(id: string) {
    this.superAdminService.getParticularInvoice(id)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          console.log("raw",rawdata);
          this.sys_gen_no = rawdata[0].sys_invoice;
          console.log("this.sys_gen_no", this.sys_gen_no);
          this.users = rawdata[0].invoice;
          console.log("this.users", this.users);
          //this.users = this.users[0].invoice;
          this.users.forEach((value: any) => {

            let waiting_charges = value.waitingCharge;
           // console.log("waiting_charges1", waiting_charges);
            this.waiting_charges = waiting_charges.reduce((a, b) => parseInt(a) + parseInt(b), 0);
           // console.log("waiting_charges2", this.waiting_charges);

            let balancepaidArray = value.vehicleRate;
           // console.log("balancepaidArray", balancepaidArray);
            this.invtot = balancepaidArray.reduce((a, b) => parseInt(a) + parseInt(b), 0);

            let unloading_charge_rcvd = value.unloadingchargercvd;
            this.unloadingchargercvd = unloading_charge_rcvd.reduce((a, b) => parseInt(a) + parseInt(b), 0);
            //console.log("this.unloadingchargercvd", this.unloadingchargercvd);

            this.total_gst_value = (this.unloadingchargercvd ? this.unloadingchargercvd : 0) + (this.invtot ? this.invtot : 0)  + (this.waiting_charges ? this.waiting_charges : 0);
          });
          this.fivePercent = (this.total_gst_value * 6) / 100;
          this.eightPercent = (this.total_gst_value * 8) / 100;
          // this.totalInvValueWithGST = this.invtot + 2*(this.fivePercent) + this.eightPercent;
          this.totalInvValueWithGST = this.total_gst_value + 2 * (this.fivePercent);
        }
        else {
          alert(res.message)
        }

      }, error => {
      });
  }
  backTo() {
    window.history.back()
  }

}
