import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-date-wise-vehicle',
  templateUrl: './date-wise-vehicle.component.html',
  styleUrls: ['./date-wise-vehicle.component.css']
})
export class DateWiseVehicleComponent implements OnInit {
  expenseArr: any = [];
  vehicleExpenseArray: any = [];
  myArr: any = [];
  paramId: any;
  users: any;
  month: any;
  userSessionData: any = JSON.parse(sessionStorage.getItem('userSession'));
  monthArrFull: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  monthArr: any = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  paramCompany: any;
  paramSupervisor: any;
  year: any;
  dtOptions: any = {};
  veh_no: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService, private spin: NgxSpinnerService, private superadminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.paramCompany = param.company;
      this.paramSupervisor = param.supervisor; 
      this.month = this.monthArrFull[this.monthArr.indexOf(this.paramId)];
      this.year = param.year;
      this.veh_no = param.vehicle_no
      console.log("param",param);
    })
  }

  ngOnInit(): void {
    this.getVehicleExpenseGroup();

    this.dtOptions = {
      dom: 'Bfrtip',

      buttons: [
        {
          extend: 'csv',
          text: 'Export'
        }

      ]
    }
  }



  getVehicleExpenseGroup() {
    this.spin.show();
    this.superadminService.getVehicleExpenseParticularMonth()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.spin.hide();
          this.superadminService.getClientList()
            .subscribe((client: { status: any, message: any, data: any }) => {
              if (client.status == true) {
                this.myArr = res.data;
                console.log("this.myArr1", this.myArr);
                this.myArr = this.myArr.filter((item) => {
                  return item._id.year == this.year
                })
                console.log("this.myArr2", this.myArr);
                if (this.userSessionData.user_role_id[0] != 1) {
                  this.myArr = this.myArr.filter((item) => {
                    return item._id.supervisorId == this.userSessionData._id
                  })
                }
                this.myArr = this.myArr.filter((item) => {
                  return this.monthArr[item._id.month - 1] == this.paramId
                })
                console.log("this.myArr3", this.myArr);
                // this.myArr = this.myArr.filter((x: any) => x._id.supervisorId === this.paramSupervisor)
                
                for (let i = 0; i < this.myArr.length; i++) {
                  this.expenseArr.push({ "supervisorId": this.myArr[i]._id.supervisorId, "companyId": this.myArr[i]._id.companyId, "LR_no": this.myArr[i]._id.LR_no, "balancePaid": this.myArr[i]._id.paymentReceived, "Advance": this.myArr[i]._id.Advance, "amount": this.myArr[i].amount, "vehicleRate": this.myArr[i]._id.vehicleRate, "balanceDue": this.myArr[i]._id.paymentDue, "Date": this.myArr[i]._id.Date, "income": this.myArr[i]._id.totalIncome, "vehicleNo": this.myArr[i]._id.vehicleNo, "total_value": this.myArr[i]._id.total_value , "total_vehicle_expense": this.myArr[i]._id.total_vehicle_expense , "payment_Due": this.myArr[i]._id.payment_Due  })

                }
                 console.log("123321",this.myArr)
                this.expenseArr.forEach((x, index) => {
                  client.data.filter(y => {
                    if (y._id == x.companyId) this.expenseArr[index].companyName = y.clientName
                  })
                })
                this.superadminService.getSuperVisorName().subscribe((supervisors: { status: any, message: any, data: any }) => {
                  if (supervisors.status == true) {
                    var supervisorsData = supervisors.data;
                    this.expenseArr.forEach((item, i) => {
                      this.expenseArr[i].SupervisorName = supervisorsData.filter((item) => this.expenseArr[i].supervisorId == item._id);
                      this.expenseArr[i].SupervisorName = this.expenseArr[i].SupervisorName[0] ? this.expenseArr[i].SupervisorName[0]['full_name'] : this.expenseArr[i].SupervisorName[0]
                    })
                    this.vehicleExpenseArray = this.expenseArr;
                    console.log("this.vehicleExpenseArray",this.vehicleExpenseArray);

                    console.log("this.veh_no", this.veh_no);
                    
                    this.vehicleExpenseArray = this.vehicleExpenseArray.filter((item) => {
                      return item.vehicleNo === this.veh_no
                    })
                  } else {
                    // console.log(res.message);
                  }
                }, error => {
                  // console.log('error', error);
                })
              }
              else {
                if (res.status == 401) {
                  this.toastr.error(res.message);
                  window.sessionStorage.clear();
                  this.router.navigateByUrl('superAuth/login');

                } else {
                  this.toastr.error(res.message);
                }
              }

            }, error => {
            });
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      }
      )
  }



  backTo() {
    window.history.back()
  }

}
