import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
  dtOptions: any = {};
  dispenseArray:any=[];
  supers:any=[];
  userSessionData:any=JSON.parse(sessionStorage.getItem('userSession'));
  myArr:any =[]
  paramId:any;
  month:any;
  monthArrFull : any=["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  monthArr : Array<String>=["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  year: any;
  constructor(private activatedRoute: ActivatedRoute,private router: Router, private toastr: ToastrService, private superadminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.month = this.monthArrFull[this.monthArr.indexOf(this.paramId)];
      this.year = param.year;
    })
   }

  ngOnInit(): void {
    this.getDispense();
    this.dtOptions = {
      dom: 'Bfrtip',

      buttons: [
        {
          extend: 'csv',
          text: 'Export'
        }

      ]
    }
  }

 getDispense() {
    this.superadminService.getDispenseMonthWise()
    .subscribe((res: { status: any, message: any, data: any }) => {
      // console.log("testin", res.data)
      if(res.status == true ){
        this.superadminService.getClientList()
          .subscribe((client: { status: any, message: any, data: any }) => {
            if (client.status == true) {
              this.myArr= res.data;
              // console.log("234423423",this.myArr) 
              if(this.dispenseArray.supervisorId == this.myArr)
              this.myArr = this.myArr.filter((item)=>{
                return item._id.year == this.year
              })
              if(this.userSessionData.user_role_id[0]!=1){
                this.myArr = this.myArr.filter((item)=>{
                  return item._id.supervisorId==this.userSessionData._id
                })
              }
              this.myArr = this.myArr.filter((item)=>{
                return this.monthArr[item._id.month-1] == this.paramId
              })
              for(let i=0; i<this.myArr.length; i++){
                this.dispenseArray.push({"supervisorId":this.myArr[i]._id.supervisorId,"companyId":this.myArr[i]._id.companyId, "amount":this.myArr[i].amount  })
              }
              this.dispenseArray.forEach((x, index) => {
                // console.log("dispenseAray",this.dispenseArray[index].companyId)
              // console.log("client", client)
                client.data.filter(y => {
                  debugger;
                  if (x.companyId === y._id ) {
                  // console.log(this.dispenseArray)
                  this.dispenseArray[index].companyId = x.companyId
                  this.dispenseArray[index].companyName = y.clientName
                  }
                })
              })
              this.superadminService.getSuperVisorName().subscribe((supervisors: {status: any, message: any, data: any}) => {
                if(supervisors.status == true){
                  var supervisorsData = supervisors.data;
                  this.dispenseArray.forEach((item,i)=>{
                    this.dispenseArray[i].SupervisorName = supervisorsData.filter((item)=> this.dispenseArray[i].supervisorId==item._id);
                    this.dispenseArray[i].SupervisorName = this.dispenseArray[i].SupervisorName[0]?this.dispenseArray[i].SupervisorName[0]['full_name']:this.dispenseArray[i].SupervisorName[0]
                  })
                  // this.dispenseArray = this.myArr
                  // console.log("ssssffff",this.dispenseArray)
                }else{
                  // console.log(res.message);
                }
              }, error => {
                // console.log('error', error);
              })
            }
            else {
              if (res.status == 401) {
                this.toastr.error(res.message);
                window.sessionStorage.clear();
                this.router.navigateByUrl('superAuth/login');

              } else {
                this.toastr.error(res.message);
              }
            }

          }, error => {
          });
      }else{
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.error(res.message);
        }
      } 
    }
    )}



// getDispense(){
//   this.superadminService.getDispense()
//       .subscribe((res: { status: any, message: any, data: any }) => {
//         if (res.status == true) {
//           this.superadminService.getClientList()
//             .subscribe((client: { status: any, message: any, data: any }) => {
//               if (client.status == true) {
//                 var rawdata = res.data;
//                 this.myArr = rawdata;
//                 this.myArr = this.myArr.filter((item)=>{
//                   return item._id.year == this.year
//                 })
//                 if(this.userSessionData.user_role_id[0]!=1){
//                   this.myArr = this.myArr.filter((item)=>{
//                     return item._id.supervisorId==this.userSessionData._id
//                   })
//                 }
//                 this.myArr = this.myArr.filter((item)=>{
//                   return this.monthArr[item._id.month-1] == this.paramId
//                 })
//                 for(let i=0; i<this.myArr.length; i++){
//                   this.dispenseArray.push({"supervisorId":this.myArr[i]._id.supervisorId,"companyId":this.myArr[i]._id.companyId, "amount":this.myArr[i].amount  })
//                 }
//                 this.dispenseArray.forEach((x, index) => {
//                   client.data.filter(y => {
//                     if (y._id == x.companyId) this.dispenseArray[index].companyName = y.clientName
//                   })
//                 })
//                 this.superadminService.getSuperVisorName().subscribe((supervisors: {status: any, message: any, data: any}) => {
//                   if(supervisors.status == true){
//                     var supervisorsData = supervisors.data;
//                     this.dispenseArray.forEach((item,i)=>{
//                       this.dispenseArray[i].SupervisorName = supervisorsData.filter((item)=> this.dispenseArray[i].supervisorId==item._id);
//                       this.dispenseArray[i].SupervisorName = this.dispenseArray[i].SupervisorName[0]?this.dispenseArray[i].SupervisorName[0]['full_name']:this.dispenseArray[i].SupervisorName[0]
//                     })
//                   }else{
//                     console.log(res.message);
//                   }
//                 }, error => {
//                   console.log('error', error);
//                 })
//               }
//               else {
//                 if (res.status == 401) {
//                   this.toastr.error(res.message);
//                   window.sessionStorage.clear();
//                   this.router.navigateByUrl('superAuth/login');

//                 } else {
//                   this.toastr.error(res.message);
//                 }
//               }

//             }, error => {
//             });

//         }
//         else {
//           if (res.status == 401) {
//             this.toastr.error(res.message);
//             window.sessionStorage.clear();
//             this.router.navigateByUrl('superAuth/login');
            
//           }else {
//             this.toastr.error(res.message);
//           }
//         }
//       }, error => {
//       });
// }

            
            
          
  backTo(){
    window.history.back()
  }
}
