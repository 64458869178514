import { Component, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';
import { AddfuelExpenseComponent } from '../addfuel-expense/addfuel-expense.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-advance',
  templateUrl: './advance.component.html',
  styleUrls: ['./advance.component.css']
})
export class AdvanceComponent {
  fuelSlipPreview: any;
  submitBtn: boolean = false;
  paramId: any;
  ParticularadvanceExpense: any;
  attach_Slips: Array<File> = [];
  id: any;
  getadvanceExpenseofParticularLR_data: any;
  Attachments: any;
  submitLRClicked = false;
  updated_edvance_exp: any;
  advanceUpdated = new EventEmitter<number>();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public formBuilder: FormBuilder, public superAdminServices: SuperAdminService, public toaster: ToastrService, private toster: ToastrService, private router: Router, private activatedRoute: ActivatedRoute, public dialogRef: MatDialogRef<AddfuelExpenseComponent>, private sanitizer: DomSanitizer) {

    this.activatedRoute.params.subscribe((param) => {
      this.id = param.id;
      // this.getadvanceExpenseofParticularLR(this.paramId)
      console.log("paramaa", this.data);
      console.log("this.data.lrID",this.data.lrID);
      //console.log("paramaa",param);
      //console.log("this.dataatatatatata",this.data);
      console.log("data1122", this.data.edit)
      if (this.data.edit != undefined) {
        this.getParticularadvanceExpense(this.data.edit);
      }
    })

  }

  ngOnInit(): void {
    this.get_all_advance_expense();
    

    // this.add_advance_exp.get('advance_amount').valueChanges.subscribe(value => {
    //   this.updateOriginalAdvance(value);
    // });
   

  }
  get ff() {
    return this.add_advance_exp.controls
  }

  add_advance_exp = this.formBuilder.group({
    advance_amount: ['', Validators.required],
    Avance_date_of_expenses: ['', Validators.required],
    Payment_mode: ['', Validators.required],
    description: [''],
    advance_slip: [''],
    lr_id: this.data.add,

  })

  // advanceExpenseSubmit() {
  //   this.submitBtn = true;
  //   if (this.data.edit) {
  //     if (this.add_advance_exp.valid) {
  //       let form = {
  //         "advance_amount": this.add_advance_exp.get('advance_amount').value,
  //         "Avance_date_of_expenses": this.add_advance_exp.get('Avance_date_of_expenses').value,
  //         "Payment_mode": this.add_advance_exp.get('Payment_mode').value,
  //         "description": this.add_advance_exp.get('description').value,
  //         "advance_slip": this.add_advance_exp.get('advance_slip').value,
  //         // fuelAttachment: ,
  //         "lr_id": this.paramId,
  //         "_id": this.data.edit

  //       }
  //       // console.log(form)
  //       const formData: any = new FormData();
  //       const files: Array<File> = this.attach_Slips
  //       // console.log("uploaded File", this.fuelSlips)

  //       formData.append("uploads[]", this.attach_Slips[0])
  //       formData.append("myFormData", JSON.stringify(form))

  //       // for (const value of formData.values()) {
  //       //   console.log("formdata",value);
  //       // }

  //       this.superAdminServices.update_advance_Expense(formData).subscribe((res: { rawdata: any, status: any, message: any, authorization: any, code: any }) => {
  //         if (res.status == true) {
  //           this.toaster.success(res.message)
  //           this.dialogRef.close(res.status);

  //         } else {
  //           if (res.status == 401) {
  //             this.toaster.error(res.message)
  //           } else {
  //             this.toaster.error(res.message)
  //           }
  //         }
  //       })
  //     }

  //     // this.superAdminServices.addFuelExpense({formData}).subscribe((res: any) => {

  //     // })
  //   } else {
  //     if (this.data.add) {
  //       if (this.add_advance_exp.valid) {
  //         this.submitBtn = true;
  //         let form = {

  //           "advance_amount": this.add_advance_exp.get("advance_amount").value,
  //           "Avance_date_of_expenses": this.add_advance_exp.get("Avance_date_of_expenses").value,
  //           "Payment_mode": this.add_advance_exp.get('Payment_mode').value,
  //           "description": this.add_advance_exp.get('description').value,
  //           "advance_slip": this.add_advance_exp.get('advance_slip').value,

  //           "lr_id": this.data.add,


  //         }
  //         const formData: any = new FormData();
  //         const files: Array<File> = this.attach_Slips
  //         // console.log("upload data", this.fuelSlips)
  //         // for (let i = 0; i < this.fuelSlips.length; i++) {


  //         // }
  //         formData.append("uploads[]", this.attach_Slips[0])
  //         formData.append("myFormData", JSON.stringify(form))
  //         // console.log("doinnn", formData.values())

  //         // for (const value of formData.values()) {
  //         //   console.log("formdata",value);
  //         // }

  //         this.superAdminServices.add_advance_Expense(formData).subscribe((res: { rawdata: any, status: any, message: any, authorization: any, code: any }) => {
  //           if (res.status == true) {
  //             this.toaster.success(res.message);
  //             this.dialogRef.close(res.status);

  //           } else {
  //             if (res.status == 401) {
  //               this.toaster.error(res.message)
  //             } else {
  //               this.toaster.error(res.message)
  //             }
  //           }

  //         })



  //       }
  //     }
  //   }
  // }


  advanceExpenseSubmit() {
    this.submitBtn = true;

    console.log("this.data.lrID",this.data.lrID);
    if (this.data.heading == 'Edit') {
      console.log("CCCCCheck", this.data.edit)
      let form = {
        ...this.add_advance_exp.value,
        _id: this.data.edit,
        lr_id: this.data.lrID
      }
      let id = this.data.edit
      console.log("FORM", form, id)
     
      this.superAdminServices.update_advance_Expense({ form, id }).subscribe((res: { status: true, message: any, authorization: any, code: any }) => {
        if (res.status == true) {
          this.toster.success(res.message);
          this.dialogRef.close(res.status);
          window.location.reload();
          console.log("checkkkkssksksksksksks");
        } else {
          if (res.status == 401) {
            this.toster.success(res.message);
          } else {
            this.toster.error(res.message);
          }
        }
      })
    } else {

      if (this.add_advance_exp.valid) {
        this.submitBtn = true;
        let form = this.add_advance_exp.value;
         console.log("form", form);
        // const formData = new FormData();
        // const files: Array<File> = this.attach_Slips;

        // for (let i = 0; i < this.attach_Slips.length; i++) {
        //   formData.append("uploads[]", this.attach_Slips[i]);
        // }
        // formData.append('myFormData', JSON.stringify(form));


        this.superAdminServices.add_advance_Expense({ form }).subscribe(
          (res: {
            status: any;
            message: any;
            authorization: any;
            code: any;
          }) => {
            // console.log("rrrccccrc", res);

            if (res.status == true) {
              this.toster.success(res.message);
              // this.add_advance_exp.reset();
              window.location.reload();
              this.dialogRef.close(res.status);
            } else {
              if (res.status == 401) {
                this.toster.error(res.message);
                // window.sessionStorage.clear();
                // this.router.navigateByUrl("superAuth/login");
              } else {
                this.toster.error(res.message);
              }
            }
          },
          (error) => { }
        );
      } else {
        this.add_advance_exp.markAllAsTouched();
        alert("all required");
      }
    }
  }



  getParticularadvanceExpense(id: any) {
    console.log("ididididdi", id);
    this.superAdminServices.getParticularadvanceExpense(id).subscribe((res: any) => {
      if (res.status == true) {
        this.ParticularadvanceExpense = res.data
        console.log("ParticularadvanceExpense", this.ParticularadvanceExpense);
        this.initForm();

      } else {
        if (res.status == 401) {
          this.toaster.error(res.message)
        } else {
          this.toaster.error(res.message)
        }
      }
    })
  }

  getadvanceExpenseofParticularLR(id: any) {
    this.superAdminServices.getadvanceExpenseofParticularLR(id).subscribe((res => {
      // console.log("ddddddaaaaaaa",res);
      if (res.status === true) {
        let dataa = res.data;
        this.getadvanceExpenseofParticularLR_data = dataa
        this.initForm();
        // console.log("daataa",this.getadvanceExpenseofParticularLR_data);
      }
    }))

  }
  initForm() {
    console.log("amount", this.ParticularadvanceExpense)
    this.add_advance_exp.patchValue({

      advance_amount: this.ParticularadvanceExpense[0].advance_amount,
      Avance_date_of_expenses: this.ParticularadvanceExpense[0].Avance_date_of_expenses,
      Payment_mode: this.ParticularadvanceExpense[0].Payment_mode,
      description: this.ParticularadvanceExpense[0].description,


    })
  };

  // fileChangeEventPODAttchment(fileInput: any) {
  //   // console.log("attachPOD", fileInput.target.files)
  //   // this.fuelSlips = [];
  //   // var reader = new FileReader();
  //   // for (var i = 0; i < fileInput.target.files.length; i++) {
  //   //   this.fuelSlips.push(fileInput.target.files[i]);
  //   //   // console.log("change event on upload file", this.fuelSlips[i])
  //   // }
  //   // reader.readAsDataURL(fileInput.target.files[0]);
  //   // reader.onload = (event: any) => { // called once readAsDataURL is completed
  //   //   // console.log(event);
  //   //   const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string)
  //   //   this.url = event.target.result;
  //   //   this.fuelSlipPreview = reader.result;
  //   // }
  // }

  // fileChangeEventPODAttchment(fileInput: any) {
  //   this.attach_Slips=[];


  //   //  this.attach_Slips.push(fileInput.target.files[0] );

  //    const reader = new FileReader();

  //    for (var i = 0; i < fileInput.target.files.length; i++) {
  //     this.attach_Slips.push(fileInput.target.files[i]);
  //     console.log("change event on upload file", this.attach_Slips);
  //   }
  //   console.log("attachslipsssssss",this.attach_Slips);

  //    reader.readAsDataURL(fileInput.target.files[0])
  //      reader.onload = (event: any) => {
  //        const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
  //        this.Attachments = reader.result;
  //      }
  //      console.log("this.attachment", this.Attachments , reader.result);
  //  }


  //  resetimg(image:any){
  //   switch (image) {
  //     case 'attechment':
  //       this.Attachments =''
  //       break;
  //     default:

  //   }
  // }

  reloadPage() {
    window.location.reload()
  }


  get_all_advance_expense() {
    this.superAdminServices.get_all_advance_expense().subscribe((res => {

      if (res.status == true) {
        let expense_data = res.data;
       

      


      }
    })

    )
  }



}
